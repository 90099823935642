import { combineURL, locationReplace } from '@maxtropy/components';
import { TenantMenuOpenType } from '@maxtropy/components/es/type';
import { Spin } from 'antd';
import { CSSProperties, FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentNavigation } from '../../api/staff';

const style: CSSProperties = {
  width: '100%',
  minHeight: 'calc(100vh - 50px)',
};

function linkUrl(path: string) {
  const hasOrigin = /^http/.test(path);
  if (hasOrigin) {
    const url = new URL(path);
    return path.slice(url.origin.length);
  }
  return path;
}

const HomeLanding: FC = () => {
  const navigate = useNavigate();

  const navigationPath = useCallback(() => {
    getCurrentNavigation().then(res => {
      if (!res) {
        navigate('/home', {
          replace: true,
        });
        return;
      }

      let path: string;
      if (res.moduleOrigin) {
        path = combineURL(res.moduleOrigin, res.path);
      } else {
        path = res.path!;
      }
      const hasOrigin = /^http/.test(path);
      if (hasOrigin) {
        const url = new URL(path);
        if (url.origin !== origin) {
          locationReplace(path);
          return;
        }
      }
      if (res.openType === TenantMenuOpenType.CurrentPage) {
        navigate(linkUrl(res.path!), {
          replace: true,
        });
      } else {
        locationReplace(path);
      }
    });
  }, [navigate]);

  useEffect(() => {
    navigationPath();
  }, [navigationPath]);

  return (
    <Spin>
      <div style={style}></div>
    </Spin>
  );
};

export default HomeLanding;
