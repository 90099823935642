import { ExclamationCircleFilled } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
const QingFlowError: React.FC = () => {
  const [searchParams] = useSearchParams();
  const msg = searchParams.get('msg');
  return (
    <>
      <div className={styles.flex_column}>
        <div className={styles.empty}></div>
        <div className={styles.warning_icon}>
          <ExclamationCircleFilled style={{ fontSize: '44px', color: '#FAAD14' }} />
          <div className={styles.msg}>{msg}</div>
        </div>
      </div>
    </>
  );
};
export default QingFlowError;
