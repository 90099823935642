import { Wrapper, useBreadcrumbRoutes, Button, Form, FormTitle, SubContent } from '@maxtropy/components';
import { FC, useCallback, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import { BusinessScope, BusinessScopeDisplay } from '../../../api/const';
import ShowInput from '../../../components/ShowInput';
import { getPropertyManagementConfig, PropertyManagementConfigResponse } from '../../../api/propertyManagementConfig';
import SelectPropMgmtDeptBusinessStaff from '../components/PropMgmtDeptBusinessStaff';
import { getTaxPoint, TaxPointRespnse } from '../../../api/taxPoint';
import { getPowerTierConfigList, PowerTierConfigResponse } from '../../../api/powerFactorConfig';
import PowerFactorConfigModal from '../components/PowerFactorConfigModal';
import CheckBusinessScope from '../components/CheckBusinessScope';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: `查看物业管理配置` }];

interface PropMgmtDeptFormProps extends Omit<PropertyManagementConfigResponse, 'id' | 'code' | 'customerName'> {}

const DetailPropMgmtDept: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<PropMgmtDeptFormProps>();
  const navigate = useNavigate();

  const [mcid, setMcid] = useState<string>();

  const [powerTierConfigs, setPowerTierConfigs] = useState<Array<PowerTierConfigResponse>>([]);
  // const [powerTierConfigId, setPowerTierConfigId] = useState<number>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getPowerTierConfigList().then(res => setPowerTierConfigs(res.list ?? []));
  }, []);

  const [data, setData] = useState<PropertyManagementConfigResponse>();

  useEffect(() => {
    if (id) {
      getPropertyManagementConfig(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setMcid(data.customerMcid);
      // setPowerTierConfigId(data.electricityDistributionSalesConfig.powerTierConfigId)
      form.setFieldsValue({
        ...data,
      });
    }
  }, [data, form]);

  const [taxPoints, setTaxPoints] = useState<TaxPointRespnse[]>([]);

  useEffect(() => {
    getTaxPoint().then(res => setTaxPoints(res.list ?? []));
  }, []);

  const goList = useCallback(() => {
    navigate(`/system/operation/prop-mgmt-dept`);
  }, [navigate]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="资产管理信息配置" />
      <Form layout="vertical" form={form} {...formLayout}>
        <SubContent>
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item name="code" label="物业管理配置编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="customerName" label="关联组织">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="salesOrg" label="销售单位">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="invoiceAddress" label="开票地址">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="taxId" label="纳税号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="beneficialBank" label="开户行">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="bankAccount" label="账号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={24} className={styles.col}>
              <Form.Item name="businessScopes" label="业务范围">
                <CheckBusinessScope
                  disabled
                  options={[
                    {
                      label: BusinessScopeDisplay[BusinessScope.ELECTRICITYDISTRIBUTIONSALES],
                      value: BusinessScope.ELECTRICITYDISTRIBUTIONSALES,
                    },
                    { label: BusinessScopeDisplay[BusinessScope.NEWENERGY], value: BusinessScope.NEWENERGY },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Form.Item noStyle dependencies={['businessScopes']}>
          {({ getFieldValue }) =>
            ((getFieldValue('businessScopes') ?? []) as BusinessScope[]).includes(
              BusinessScope.ELECTRICITYDISTRIBUTIONSALES
            ) && (
              <SubContent title={BusinessScopeDisplay[BusinessScope.ELECTRICITYDISTRIBUTIONSALES]}>
                <Row>
                  <Col span={8} className={styles.col}>
                    <Form.Item name={['electricityDistributionSalesConfig', 'billTitle']} label="核查联表头">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item name={['electricityDistributionSalesConfig', 'contactNumber']} label="供电服务热线">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item noStyle dependencies={['electricityDistributionSalesConfig', 'taxPoint']}>
                      {({ getFieldValue }) => {
                        const taxPoint = getFieldValue(['electricityDistributionSalesConfig', 'taxPoint']);
                        const find = taxPoints.find(i => i.value === taxPoint);
                        return (
                          <Form.Item label="税点">
                            <ShowInput value={find?.name} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={24} className={styles.col}>
                    <Form.Item label="功率因数调整电费比例">
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <Form.Item noStyle dependencies={['electricityDistributionSalesConfig', 'powerTierConfigId']}>
                          {({ getFieldValue }) => {
                            const powerTierConfigId = getFieldValue([
                              'electricityDistributionSalesConfig',
                              'powerTierConfigId',
                            ]);
                            const find = powerTierConfigs.find(i => i.id === powerTierConfigId);
                            return (
                              <Form.Item>
                                <ShowInput value={find?.name} />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                        <PowerFactorConfigModal /*powerTierConfigId={powerTierConfigId}*/ />
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className={styles.col}>
                    <Form.Item name={['electricityDistributionSalesConfig', 'opStaffList']} wrapperCol={{ span: 24 }}>
                      <SelectPropMgmtDeptBusinessStaff disabled mcid={mcid ?? data?.customerMcid} />
                    </Form.Item>
                  </Col>
                </Row>
              </SubContent>
            )
          }
        </Form.Item>
        <Form.Item noStyle dependencies={['businessScopes']}>
          {({ getFieldValue }) =>
            ((getFieldValue('businessScopes') ?? []) as BusinessScope[]).includes(BusinessScope.NEWENERGY) && (
              <SubContent title={BusinessScopeDisplay[BusinessScope.NEWENERGY]}>
                <Row>
                  <Col span={8} className={styles.col}>
                    <Form.Item name={['propertyManagementNewEnergyConfig', 'billTitle']} label="核查联表头">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item name={['propertyManagementNewEnergyConfig', 'companyName']} label="能源公司名称">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item name={['propertyManagementNewEnergyConfig', 'companyAddress']} label="能源公司地址">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item name={['propertyManagementNewEnergyConfig', 'contactNumber']} label="供电服务热线">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item noStyle dependencies={['propertyManagementNewEnergyConfig', 'taxPoint']}>
                      {({ getFieldValue }) => {
                        const taxPoint = getFieldValue(['propertyManagementNewEnergyConfig', 'taxPoint']);
                        const find = taxPoints.find(i => i.value === taxPoint);
                        return (
                          <Form.Item label="税点">
                            <ShowInput value={find?.name} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className={styles.col}>
                    <Form.Item name={['propertyManagementNewEnergyConfig', 'opStaffList']} wrapperCol={{ span: 24 }}>
                      <SelectPropMgmtDeptBusinessStaff disabled mcid={mcid ?? data?.customerMcid} />
                    </Form.Item>
                  </Col>
                </Row>
              </SubContent>
            )
          }
        </Form.Item>
        <Space className="sticky-footer-left">
          <Button onClick={goList}>返回</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default DetailPropMgmtDept;
