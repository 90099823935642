import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Empty,
  useTenantPermissions,
  useThemeContext,
  Themes,
  getBase,
} from '@maxtropy/components';
import { Row, Col, Typography, Card, Space, Grid } from 'antd';
import styles from './screen.module.scss';
import greenDot from '../../assets/image/greenDot.svg';
import redDot from '../../assets/image/redDot.svg';
import CardsWrapperSection from './components/CardsWrapperSection';
import battery from '../../assets/image/battery.svg';
import LampImage from './components/LampImage';
import propertysafety from '../../assets/image/propertysafety.svg';
import thunderbolt from '../../assets/image/thunderbolt.svg';
import CO2 from './assets/CO2-Outlined@2x.png';
import Partition from './assets/Partition@2x.png';
import BusinessTypeBanner from './components/BusinessTypeBanner';
import staff from '../../assets/image/staff.svg';
import ou from '../../assets/image/ou.svg';
import AuthBanner from './components/AuthBanner';
import EnergyAssetCard from './components/EnergyAssetCard';
import pv from '../../assets/image/pv.svg';
import circuit from '../../assets/image/circuit.svg';
import pipe from '../../assets/image/pipe.svg';
import edgeDevice from '../../assets/image/edgeDevice.svg';
import edgeDevicePoint from '../../assets/image/edgeDevicePoint.svg';
import device from '../../assets/image/device.svg';
import areachart from '../../assets/image/areachart.svg';
import alert from '../../assets/image/alert.svg';
import {
  AuthConfigStatistics,
  EdgeDeviceStatistics,
  EnergyAssetStatistics,
  NavigationsByGroupResponse,
  getAuthConfigStatistics,
  getEdgeDeviceStatistics,
  getEnergyAssetStatistics,
  getGatewayStatistics,
  getNavigationsByGroup,
  init,
} from '../../api/navScreen';
import NavButton from './components/NavButton';
import { useRequest } from 'ahooks';
import { isEmpty } from 'lodash-es';

// prettier-ignore
function CompositiveNavScreen() { // NOSONAR
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const screens = Grid.useBreakpoint();
  const [navs, setNavs] = useState<NavigationsByGroupResponse>({});
  const [energyAssetStatistics, setEnergyAssetStatistics] = useState<EnergyAssetStatistics>({});
  const [energyAssetStatisticsLoading, setEnergyAssetStatisticsLoading] = useState<boolean>(false);
  const [edgeDeviceStatistics, setEdgeDeviceStatistics] = useState<EdgeDeviceStatistics>({});
  const [edgeDeviceStatisticsLoading, setEdgeDeviceStatisticsLoading] = useState<boolean>(false);
  const [gatewayStatisticsLoading, setGatewayStatisticsLoading] = useState<boolean>(false);
  const [authConfigStatistics, setAuthConfigStatistics] = useState<AuthConfigStatistics>({});
  const [authConfigStatisticsLoading, setAuthConfigStatisticsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  // 拿所有 permissions
  const permissions = useTenantPermissions();

  const { setCustomTheme } = useThemeContext();
  
  // 精益能源分组是否至少存在导航有一个权限
  const hasLeanEnergyManagementPermission = useMemo(
    () =>
      (navs?.PLATFORM?.['精益能源管理'] ?? []).some(
        n => (permissions ?? []).findIndex(p => p === n.functionPermissionCode) !== -1
      ),
    [navs?.PLATFORM, permissions]
  );
  // 业态数据分组是否至少存在导航有一个权限
  const hasBusinessTypeDataPermission = useMemo(
    () =>
      (navs?.PLATFORM?.['业态数据管理'] ?? []).some(
        n => (permissions ?? []).findIndex(p => p === n.functionPermissionCode) !== -1
      ),
    [navs?.PLATFORM, permissions]
  );
  // 报警业务分组是否至少存在导航有一个权限
  const hasAlertPermission = useMemo(
    () =>
      (navs?.PLATFORM?.['报警业务'] ?? []).some(
        n => (permissions ?? []).findIndex(p => p === n.functionPermissionCode) !== -1
      ),
    [navs?.PLATFORM, permissions]
  );
  // 能源营销分组是否至少存在导航有一个权限
  const hasEnergyMarketingPermission = useMemo(
    () =>
      (navs?.PLATFORM?.['能源营销'] ?? []).some(
        n => (permissions ?? []).findIndex(p => p === n.functionPermissionCode) !== -1
      ),
    [navs?.PLATFORM, permissions]
  );
  // 业态配置所有分组是否至少存在导航有一个权限
  const hasBusinessTypeConfigPermission = useMemo(
    () =>
      hasLeanEnergyManagementPermission ||
      hasBusinessTypeDataPermission ||
      hasAlertPermission ||
      hasEnergyMarketingPermission,
    [hasAlertPermission, hasBusinessTypeDataPermission, hasEnergyMarketingPermission, hasLeanEnergyManagementPermission]
  );
  // 权限设置分组是否至少存在导航有一个权限
  const hasAuthConfigPermission = useMemo(
    () =>
      (navs?.PLATFORM?.['权限设置'] ?? []).some(
        n => (permissions ?? []).findIndex(p => p === n.functionPermissionCode) !== -1
      ),
    [navs?.PLATFORM, permissions]
  );

  const { data: gatewayStatistics } = useRequest(getGatewayStatistics, {
    // 15 分钟更新一次
    pollingInterval: 15 * 60 * 1000,
    pollingErrorRetryCount: 1,
    onBefore: () => {
      setGatewayStatisticsLoading(true);
    },
    onFinally: () => {
      setGatewayStatisticsLoading(false);
    },
  });

  useLayoutEffect(() => {
    setCustomTheme?.(Themes.DARK);
    if(getBase()) {
      window.microApp.dispatch({customTheme: Themes.DARK});
    }

    return () => {
      setCustomTheme?.();
      if(getBase()) {
        window.microApp.dispatch({customTheme: undefined}, () => {
          window.microApp.clearData();
        })
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 初始化
  useEffect(() => {
    init().then(res => {
      setIsInit(true);
    });
  }, []);

  // 获取所有导航
  useEffect(() => {
    if (isInit) {
      getNavigationsByGroup().then(res => {
        if (res.map) {
          setNavs(res.map);
        }
      });
    }
  }, [isInit]);

  // 获取能源资产数据
  useEffect(() => {
    setEnergyAssetStatisticsLoading(true);
    getEnergyAssetStatistics()
      .then(res => {
        if (res) {
          setEnergyAssetStatistics(res);
        }
      })
      .finally(() => {
        setEnergyAssetStatisticsLoading(false);
      });
  }, []);

  // 获取物联接入数据
  useEffect(() => {
    setEdgeDeviceStatisticsLoading(true);
    getEdgeDeviceStatistics()
      .then(res => {
        if (res) {
          setEdgeDeviceStatistics(res);
        }
      })
      .finally(() => {
        setEdgeDeviceStatisticsLoading(false);
      });
  }, []);

  // 获取权限配置数据
  useEffect(() => {
    setAuthConfigStatisticsLoading(true);
    getAuthConfigStatistics()
      .then(res => {
        if (res) {
          setAuthConfigStatistics(res);
        }
      })
      .finally(() => {
        setAuthConfigStatisticsLoading(false);
      });
  }, []);

  return (
    <Wrapper className={styles.wrapper} routes={breadcrumbRoutes?.routes ?? []}>
      <Row gutter={[16, 16]}>
        <Col span={24}  xxl={12}>
          <div style={{ display: 'flex', gap: '16px 0', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1, height: '100%' }}>
              <CardsWrapperSection
                // wrapperStyle={{ minHeight: '60%' }}
                title="能源资产管理"
                navDetails={navs?.PLATFORM?.['能源资产管理']}
                spinning={energyAssetStatisticsLoading}
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <EnergyAssetCard
                      headImageUrl={battery}
                      statistic={energyAssetStatistics.bsaCount}
                      statisticTitle="储能站点数(个)"
                      navDetails={navs?.PLATFORM?.['储能分组']}
                    />
                  </Col>

                  <Col span={12}>
                    <EnergyAssetCard
                      headImageUrl={pv}
                      statistic={energyAssetStatistics?.pvStationCount}
                      statisticTitle="光伏站点数(个)"
                      navDetails={navs?.PLATFORM?.['光伏分组']}
                    />
                  </Col>

                  <Col span={12}>
                    <EnergyAssetCard
                      headImageUrl={circuit}
                      statistic={energyAssetStatistics?.circuitCount}
                      statisticTitle="回路数(个)"
                      navDetails={navs?.PLATFORM?.['回路分组']}
                    />
                  </Col>

                  <Col span={12}>
                    <EnergyAssetCard
                      headImageUrl={pipe}
                      statistic={energyAssetStatistics?.emtProcessCount}
                      statisticTitle="管网节点数(个)"
                      navDetails={navs?.PLATFORM?.['工质分组']}
                    />
                  </Col>
                </Row>
              </CardsWrapperSection>
            </div>
            <div style={{ flexGrow: 1 }}>
              <CardsWrapperSection
                // wrapperStyle={{ minHeight: '40%' }}
                title="物联接入"
                spinning={edgeDeviceStatisticsLoading || gatewayStatisticsLoading}
              >
                <Row gutter={[16, 16]}>
                  {/* 物联接入左侧 */}
                  <Col span={12}>
                    <Card className={styles.navCard}>
                      <div
                        style={{
                          // minHeight: '25vh',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Space direction="vertical" size={64}>
                          <Space align="end">
                            <LampImage headImageUrl={edgeDevice} />
                            <div style={{ marginRight: 'auto' }}>
                              <Space direction="vertical">
                                <Typography.Title level={2} style={{ color: '#52E7FF' }}>
                                  {edgeDeviceStatistics?.edgeDeviceCount ?? '--'}
                                </Typography.Title>
                                <Typography.Text>采集设备数(个)</Typography.Text>
                              </Space>
                            </div>
                          </Space>
                          <Space align="end">
                            <LampImage headImageUrl={edgeDevicePoint} />
                            <div style={{ marginRight: 'auto' }}>
                              <Space direction="vertical">
                                <Typography.Title level={2} style={{ color: '#52E7FF' }}>
                                  {(edgeDeviceStatistics?.edgeDevicePointCount ?? undefined) !== undefined
                                    ? edgeDeviceStatistics?.edgeDevicePointCount! > 10000
                                      ? (edgeDeviceStatistics?.edgeDevicePointCount! / 10000).toFixed(2)
                                      : edgeDeviceStatistics?.edgeDevicePointCount
                                    : '--'}
                                </Typography.Title>
                                <Typography.Text>
                                  {Number(edgeDeviceStatistics?.edgeDevicePointCount) > 10000
                                    ? '采集点数量(万个)'
                                    : '采集点数量(个)'}
                                </Typography.Text>
                              </Space>
                            </div>
                          </Space>
                        </Space>

                        <Space direction="vertical" size={12}>
                          {navs?.PLATFORM?.['采集设备']
                            ? navs?.PLATFORM?.['采集设备']?.map(n => <NavButton navDetail={n} key={n.id}/>)
                            : null}
                        </Space>
                      </div>
                    </Card>
                  </Col>

                  {/* 物联接入右侧 */}
                  <Col span={12}>
                    <Card className={styles.navCard}>
                      <div
                        style={{
                          // minHeight: '25vh',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Space direction="vertical" size="large">
                          <Space align="end">
                            <LampImage headImageUrl={device} />
                            <div style={{ marginRight: 'auto' }}>
                              <Space direction="vertical">
                                <Typography.Title level={2} style={{ color: '#52E7FF' }}>
                                  {gatewayStatistics?.totalCount ?? '--'}
                                </Typography.Title>
                                <Typography.Text>物联设备数(个)</Typography.Text>
                              </Space>
                            </div>
                          </Space>

                          <div style={{ display: 'flex', gap: '0 8px' }}>
                            <Space
                              direction="vertical"
                              align="center"
                              size={4}
                              style={{
                                borderRadius: '4px',
                                border: '1px dashed rgba(74,144,226,0.6)',
                                padding: '0 8px',
                                // height: '100%',
                              }}
                            >
                              <img src={redDot} alt="" />
                              <Typography.Title level={5} style={{ color: '#F5222D' }}>
                                离线
                              </Typography.Title>
                              <Typography.Title level={2} style={{ color: '#52E7FF' }}>
                                {gatewayStatistics?.offlineCount ?? '--'}
                              </Typography.Title>
                            </Space>

                            <Space
                              direction="vertical"
                              align="center"
                              size={4}
                              style={{
                                borderRadius: '4px',
                                border: '1px dashed rgba(74,144,226,0.6)',
                                padding: '0 8px',
                                // height: '100%',
                              }}
                            >
                              <img src={greenDot} alt="" />
                              <Typography.Title level={5} style={{ color: '#42D4AA' }}>
                                在线
                              </Typography.Title>
                              <Typography.Title level={2} style={{ color: '#52E7FF' }}>
                                {gatewayStatistics?.onlineCount ?? '--'}
                              </Typography.Title>
                            </Space>
                          </div>
                        </Space>

                        <Space direction="vertical" size={12}>
                          {navs?.PLATFORM?.['物联设备']
                            ? navs?.PLATFORM?.['物联设备']?.map(n => <NavButton navDetail={n} key={n.id} />)
                            : null}
                        </Space>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </CardsWrapperSection>
            </div>
          </div>
        </Col>
        <Col span={24}  xxl={12}>
          <div style={{ display: 'flex', gap: '16px 0', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1.5, height: '100%' }}>
              <CardsWrapperSection title="业态配置" wrapperStyle={{ minHeight: '60%' }}>
                <Row gutter={[24, 32]} justify="start">
                  {hasBusinessTypeConfigPermission ? (
                    <>
                      {navs?.PLATFORM?.['精益能源管理']?.length ? (
                        <Col span={12}>
                          <BusinessTypeBanner
                            title="精益能源管理"
                            iconBackground="#42D4AA"
                            icon={<img src={thunderbolt} alt="" />}
                            titleBackground="linear-gradient(90deg, rgba(66,212,170,0) 0%, rgba(66,212,170,0.4) 100%)"
                          />
                          <Row gutter={[24, 12]}>
                            {navs?.PLATFORM?.['精益能源管理']
                              ? navs?.PLATFORM?.['精益能源管理']?.map(n => (
                                  <Col span={8} key={n.id}>
                                    <NavButton navDetail={n}/>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </Col>
                      ) : null}
                      {navs?.PLATFORM?.['业态数据管理']?.length ? (
                        <Col span={12}>
                          <BusinessTypeBanner
                            title="业态数据管理"
                            iconBackground="#445DDB"
                            // icon={<PropertySafetyFilled />}
                            image={<img src={areachart} alt="" />}
                            titleBackground="linear-gradient(90deg, rgba(68,93,219,0) 0%, rgba(68,93,219,0.4) 100%)"
                          />
                          <Row gutter={[24, 12]}>
                            {navs?.PLATFORM?.['业态数据管理']
                              ? navs?.PLATFORM?.['业态数据管理']?.map(n => (
                                  <Col span={8} key={n.id}>
                                    <NavButton navDetail={n}/>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </Col>
                      ) : null}
                      {navs?.PLATFORM?.['报警业务']?.length ? (
                        <Col span={12}>
                          <BusinessTypeBanner
                            title="报警业务"
                            iconBackground="#FC8635"
                            image={<img src={alert} alt="" />}
                            titleBackground="linear-gradient(90deg, rgba(252,134,53,0) 0%, rgba(252,134,53,0.4) 100%)"
                          />
                          <Row gutter={[24, 12]}>
                            {navs?.PLATFORM?.['报警业务']
                              ? navs?.PLATFORM?.['报警业务']?.map(n => (
                                  <Col span={8} key={n.id}>
                                    <NavButton navDetail={n}/>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </Col>
                      ) : null}
                      {navs?.PLATFORM?.['能源营销']?.length ? (
                        <Col span={12}>
                          <BusinessTypeBanner
                            title="能源营销"
                            iconBackground="#F53564"
                            icon={<img src={propertysafety} alt="" />}
                            titleBackground="linear-gradient(90deg, rgba(245,53,100,0) 0%, rgba(245,53,100,0.4) 100%)"
                          />
                          <Row gutter={[24, 12]}>
                            {navs?.PLATFORM?.['能源营销']
                              ? navs?.PLATFORM?.['能源营销']?.map(n => (
                                  <Col span={8} key={n.id}>
                                    <NavButton navDetail={n}/>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </Col>
                      ) : null}
                      {navs?.PLATFORM?.['企业碳盘查']?.length ? (
                        <Col span={12}>
                          <BusinessTypeBanner
                            title="企业碳盘查"
                            iconBackground="#42D4AA"
                            icon={<img src={CO2} alt="" style={{width:24,height:24}}/>}
                            titleBackground="linear-gradient(90deg, rgba(66,212,170,0) 0%, rgba(66,212,170,0.4) 100%)"
                          />
                          <Row gutter={[24, 12]}>
                            {navs?.PLATFORM?.['企业碳盘查']
                              ? navs?.PLATFORM?.['企业碳盘查']?.map(n => (
                                  <Col span={8} key={n.id}>
                                    <NavButton navDetail={n}/>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </Col>
                      ) : null}
                      {navs?.PLATFORM?.['生产拓扑管理']?.length ? (
                        <Col span={12}>
                          <BusinessTypeBanner
                            title="生产拓扑管理"
                            iconBackground="#445DDB"
                            image={<img src={Partition} alt="" style={{width:24,height:24}}/>}
                            titleBackground="linear-gradient(90deg, rgba(68,93,219,0) 0%, rgba(68,93,219,0.4) 100%)"
                          />
                          <Row gutter={[24, 12]}>
                            {navs?.PLATFORM?.['生产拓扑管理']
                              ? navs?.PLATFORM?.['生产拓扑管理']?.map(n => (
                                  <Col span={8} key={n.id}>
                                    <NavButton navDetail={n}/>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </Col>
                      ) : null}
                    </>
                  ) : (
                    <Col span={24}>
                      <Empty style={{ marginTop: '10vh' }} description="暂无权限" />
                    </Col>
                  )}
                </Row>
              </CardsWrapperSection>
            </div>
            <div style={{ flexGrow: 1 }}>
              <CardsWrapperSection
                title="权限设置"
                // wrapperStyle={{ minHeight: screens.xl ? '30%' : '20vh' }}
                spinning={authConfigStatisticsLoading}
              >
                <Row align="middle" gutter={[32, 32]}>
                  {hasAuthConfigPermission ? (
                    <>
                      <Col span={10} xxl={24} xl={24}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '16px' }}>
                          <AuthBanner title="运营单元数" statistic={authConfigStatistics?.totalOuNum} logoUrl={staff} />
                          <AuthBanner title="员工数" statistic={authConfigStatistics?.totalStaffNum} logoUrl={ou} />
                        </div>
                      </Col>
                      <Col span={14} xxl={24} xl={24}>
                        <Row gutter={[32, 24]} style={{ margin: screens.xl ? '0 48px' : '0 16px' }}>
                          {navs?.PLATFORM?.['权限设置']?.map(n => (
                            <Col span={8} key={n.id}>
                              <NavButton navDetail={n} />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <Col span={24}>
                      <Empty description="暂无权限" />
                    </Col>
                  )}
                </Row>
              </CardsWrapperSection>
            </div>
          </div>
        </Col>
      </Row>

      {/* 可配置分组 */}
      {!isEmpty(navs?.['CUSTOM']) ? (
        <div className={styles.cardsWrapper} style={{ marginTop: 16 }}>
          <Row gutter={[16, 24]}>
            {Object.entries(navs?.['CUSTOM'] ?? {}).map(([k, v]) => {
              return (
                <Col span={12} xxl={8} xl={8} key={k}>
                  <Typography.Title level={4}>{k}</Typography.Title>
                  <Row gutter={[16, 16]} style={{ margin: '24px 16px' }}>
                    {v.map(n => (
                      <Col span={8} key={n.id}>
                        <NavButton navDetail={n} isPlatform={false}/>
                      </Col>
                    ))}
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : null}
    </Wrapper>
  );
}

export default CompositiveNavScreen;
