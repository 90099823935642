import { PlusOutlined } from '@ant-design/icons';
import {
  Modal,
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useUpdate,
  Button,
  useCurrent,
  TenantType,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, useCallback, useEffect, useState } from 'react';
import { OuStaffType, OuStaffTypeDisplay } from '../../../api/const';
import { bindStaffOu, getStaffOuOwnPage, OuResponse, unBindStaffOu, unBindStaffOuAll } from '../../../api/staff';
import OuModal, { ouColumns } from './OuModal';
import ChannelOuModal, { channelOuColumns } from './ChannelOuModal';
import {
  apiV2ChannelTenantOuPageByStaffPost,
  V2ChannelTenantOuPageByStaffPostResponse,
} from '@maxtropy/cc-customer-apis-v2';

interface OuListProps {
  staffId?: number;
}

type RowData = Exclude<V2ChannelTenantOuPageByStaffPostResponse['list'], undefined>[number];

export const columns: ColumnType<OuResponse>[] = [
  ...ouColumns,
  {
    title: '权限级别',
    dataIndex: 'memberType',
    width: 200,
    render: (v: OuStaffType) => <EllipsisSpan value={OuStaffTypeDisplay[v]} />,
  },
];

export const channelColumns: ColumnType<RowData>[] = [
  ...channelOuColumns,
  {
    title: '权限级别',
    dataIndex: 'memberType',
    width: 200,
    render: (v: OuStaffType) => <EllipsisSpan value={OuStaffTypeDisplay[v]} />,
  },
];

const OuList: FC<OuListProps> = ({ staffId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, update] = useUpdate();

  const [ouIds, setOuIds] = useState<number[]>([]);

  const [type, setType] = useState<OuStaffType>();

  const current = useCurrent();
  const isChannel = current?.tenant?.tenantType === TenantType.CHANNEL;

  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [data, setData] = useState<Array<OuResponse>>([]);
  const [channelData, setChannelData] = useState<Array<RowData>>([]);

  useEffect(() => {
    if (staffId) {
      setLoading(true);
      if (isChannel) {
        apiV2ChannelTenantOuPageByStaffPost({
          staffId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            console.log(res);
            setChannelData(res.list ?? []);
            setTotalCount(res.total ?? 0);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        getStaffOuOwnPage({
          staffId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            console.log(res);
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId, type, pageOffset, pageSize, updateState, setTotalCount]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 200,
      render: (record: OuResponse) => (
        <Space>
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              onUnBindOu(record);
            }}
          >
            移出
          </Button>
        </Space>
      ),
    },
  ];
  const buildChannelColumns = [
    ...channelColumns,
    {
      title: '操作',
      width: 200,
      render: (record: OuResponse) => (
        <Space>
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              onUnBindOu(record);
            }}
          >
            移出
          </Button>
        </Space>
      ),
    },
  ];

  const refreshData = useCallback(() => {
    setPageOffset(1);
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnBindAllOu = () => {
    if (staffId) {
      Modal.confirm({
        title: '确定移出所有运营单元？',
        content: '移出后不可恢复，你还要继续吗？',
        okText: '继续',
        onOk() {
          unBindStaffOuAll(staffId).then(() => {
            Modal.success({
              title: '移出成功',
              onOk() {
                refreshData();
              },
              onCancel() {
                refreshData();
              },
            });
          });
        },
      });
    }
  };

  const onUnBindOu = useCallback(
    (record: OuResponse) => {
      if (staffId) {
        unBindStaffOu(staffId, record.id).then(() => {
          refreshData();
        });
      }
    },
    [staffId, refreshData]
  );

  const onOk = () => {
    if (staffId && type !== undefined) {
      if (ouIds.length > 0) {
        // type=2为渠道端租户添加ou成员
        bindStaffOu({
          staffId,
          ouIds,
          ouMemberType: type === 2 ? 0 : type,
        }).then(() => {
          refreshData();
          onCancel();
        });
      } else {
        Modal.warning({
          title: '请先选择运营单元',
        });
      }
    }
  };

  const afterClose = () => {
    setOuIds([]);
  };

  const onCancel = () => {
    setType(undefined);
  };

  return (
    <>
      <Space
        size={8}
        style={{
          marginBottom: 10,
          marginTop: 8,
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            setType(OuStaffType.ADMINISTRATOR);
          }}
        >
          添加为管理员
        </Button>
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            setType(OuStaffType.MEMBER);
          }}
        >
          添加为成员
        </Button>
        {isChannel && (
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setType(OuStaffType.CHANNEL);
            }}
          >
            添加为可访问租户运营单元成员
          </Button>
        )}

        <Button onClick={onUnBindAllOu}>移出所有运营单元</Button>
      </Space>
      {isChannel ? (
        <Table loading={loading} rowKey="id" columns={buildChannelColumns} dataSource={channelData} />
      ) : (
        <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} />
      )}
      <Paging pagingInfo={pagingInfo} />
      <Modal
        size="big"
        open={type !== undefined}
        title={`添加为${type !== undefined ? OuStaffTypeDisplay[type] : ''}-运营单元选择`}
        onOk={onOk}
        onCancel={onCancel}
        afterClose={afterClose}
        destroyOnClose
      >
        {type === 2 ? (
          <ChannelOuModal staffId={staffId} type={type} ouIds={ouIds} onChange={setOuIds} />
        ) : (
          <OuModal staffId={staffId} type={type} ouIds={ouIds} onChange={setOuIds} />
        )}
      </Modal>
    </>
  );
};

export default OuList;
