import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Wrapper,
  Table,
  EllipsisSpan,
  Paging,
  usePaging,
  useCurrent,
  defaultErrorHandling,
  useBreadcrumbRoutes,
  Button,
  SearchInput,
  SearchInputOptionProps,
  PassWordIsOpen,
  Form,
  message,
  Modal,
  Input,
  Select,
  TreeSelect,
  CustomFilter,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Space } from 'antd';
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import {
  batchReassignStaff,
  disableBatchStaff,
  enableStaff,
  freezeStaff,
  getStaffList,
  resetCustomerPassword,
  StaffListRequest,
  StaffResponse,
} from '../../api/staff';
import { UserStatus, UserStatusDisplay } from '../../api/const';
import dayjs from 'dayjs';
import { getListCustomer, getOrganizationWithCodeWithCurrent, OrganizationTreeProps } from '../../api/customer';
import { getParent } from '../../lib/util';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useHasPermission } from '../../utils/hooks';
import { PermissionsType } from '../../common/permissionsConst';
import CopyToClipboard from 'react-copy-to-clipboard';

const columns = [
  {
    title: '用户ID',
    dataIndex: 'customerUserHumanCode',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '姓名',
    dataIndex: 'customerUserName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '账号',
    dataIndex: 'customerUserUsername',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'customerUserCellphone',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工号',
    dataIndex: 'staffCode',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '角色',
    dataIndex: 'roleName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织简称',
    dataIndex: 'customerName',
    width: 140,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织全称',
    dataIndex: 'customerFullName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },

  {
    title: '工号状态',
    dataIndex: 'status',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: UserStatus) => <EllipsisSpan value={UserStatusDisplay[v]} />,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后修改时间',
    dataIndex: 'updateTime',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const batchColumns = [
  {
    title: '原所属用户ID',
    dataIndex: 'customerUserHumanCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原所属用户登录账号',
    dataIndex: 'customerUserUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '姓名',
    dataIndex: 'customerUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原所属用户手机号',
    dataIndex: 'customerUserCellphone',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const formatTreeData = (data: OrganizationTreeProps[], parentList: string[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      ...item,
      value: item.data?.mcid ?? '',
      key: item.data?.mcid ?? '',
      title: item.data?.name,
      disabled: parentList.includes(item.data!.mcid),
      children: formatTreeData(item.children, parentList),
    };
    return res;
  });
};

export interface FilterParams extends Omit<StaffListRequest, 'page' | 'size' | 'mcids'> {
  mcids?: string;
}

const Employee: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const pathMcids = search ? search.split('=')[1] : '';
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<FilterParams>({
    mcids: pathMcids || undefined,
  });
  const [dataSource, setDataSource] = useState<StaffResponse[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRowRecords, setSelectedRowRecords] = useState<StaffResponse[]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const [batchReassignSelectedRowRecords, setBatchReassignSelectedRowRecords] = useState<StaffResponse[]>([]);
  const [muid, setMuid] = useState<string>();
  const [option, setOption] = useState<SearchInputOptionProps[]>([]);
  const [passwordOpened, setPasswordOpened] = useState<boolean>(false);
  const [operationPassword, setOperationPassword] = useState<string>('');
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const user = useCurrent();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const hasStaffOuMange = useHasPermission(PermissionsType.B_STAFF_OU_MANAGE);

  // 当前用户租户等详情
  const current = useCurrent();

  const passwordOpSwitch = current?.tenant?.passwordOpSwitch;

  useEffect(() => {
    setLoading(true);
    getStaffList({
      ...searchParams,
      mcids: searchParams.mcids ? searchParams.mcids.split(',') : undefined,
      page: pageOffset,
      size: pageSize,
    })
      .then(data => {
        setTotalCount(data.total);
        setDataSource(data.list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, pageOffset, pageSize, setTotalCount]);

  useEffect(() => {
    if (user && user.customer && user.customer.mcid) {
      getOrganizationWithCodeWithCurrent().then(data => {
        if (data && user.customer && user.customer.mcid) {
          const parentList = getParent([data], user.customer.mcid);
          setTreeData(formatTreeData([data], parentList));
        }
      });
    }
  }, [user]);

  const onFinish = (value: FilterParams) => {
    setSearchParams({ ...value });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({ mcids: pathMcids });
    setPageOffset(1);
  };

  const [searchForm] = Form.useForm();

  const filters = (
    <CustomFilter<FilterParams> form={searchForm} onFinish={val => onFinish(val)} onReset={onReset} colSpan={6}>
      <Form.Item name="humanCode" label="用户ID">
        <Input placeholder="请输入完整用户ID" />
      </Form.Item>
      <Form.Item name="username" label="用户登录账号">
        <Input placeholder="请输入用户登录账号" />
      </Form.Item>
      <Form.Item name="name" label="姓名">
        <Input placeholder="请输入姓名的全部/部分" />
      </Form.Item>
      <Form.Item name="status" label="用户状态">
        <Select placeholder="请选择状态">
          <Select.Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Select.Option>
          <Select.Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Select.Option>
          <Select.Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="staffCode" label="员工工号">
        <Input placeholder="请输入员工工号" />
      </Form.Item>
      <Form.Item name="staffStatus" label="员工工号状态">
        <Select placeholder="请选择员工工号状态">
          <Select.Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Select.Option>
          <Select.Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Select.Option>
          <Select.Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="cellphone" label="手机号">
        <Input placeholder="请输入完整用户手机号" />
      </Form.Item>
      <Form.Item name="mcids" label="所在组织">
        <TreeSelect placeholder="请选择所在组织" treeData={treeData} />
      </Form.Item>
    </CustomFilter>
  );

  const onSelectChange = (keys: Key[], slectRecords: StaffResponse[]) => {
    setSelectedRowKeys(keys as number[]);
    setSelectedRowRecords(slectRecords);
  };

  const getSelectedRowRecords = useCallback(() => {
    if (!selectedRowKeys) return [];
    return dataSource.filter(i => selectedRowKeys.includes(i.id));
  }, [selectedRowKeys, dataSource]);

  const batchButtonDisable = useMemo(() => {
    if (!selectedRowKeys || selectedRowKeys.length === 0) return true;
    return getSelectedRowRecords().some(i => i.status === UserStatus.DISABLE);
  }, [selectedRowKeys, getSelectedRowRecords]);

  const onDisableBatch = () => {
    Modal.confirm({
      title: <p>禁用账号</p>,
      className: styles.modal,
      content: (
        <div>
          即将禁用员工账号{(selectedRowRecords ?? []).map(i => i.customerUserName).join(',')}, 点击确认以操作该执行
        </div>
      ),
      onOk: () => {
        return disableBatchStaff(selectedRowKeys)
          .then(res => {
            if (res.result) {
              return onSuccess(
                <div>已禁用账号{(selectedRowRecords ?? []).map(i => i.customerUserName).join(',')}</div>
              );
            }
          })
          .then(() => {
            setSearchParams({ ...searchParams });
            setSelectedRowKeys([]);
          });
      },
    });
  };

  const onSuccess = useCallback((content: React.ReactNode) => {
    return new Promise(resolve => {
      Modal.success({
        title: '操作成功',
        className: styles.modal,
        content,
        okText: '关闭',
        onOk: () => {
          resolve(undefined);
        },
        onCancel: () => {
          resolve(undefined);
        },
      });
    });
  }, []);

  const onBatchReassignStaff = (isMultiple: boolean = true) => {
    if (isMultiple) {
      setSelectedRowRecords(getSelectedRowRecords());
    }
    setOpened(true);
  };

  useEffect(() => {
    if (!opened) {
      setSelectedRowRecords([]);
      setBatchReassignSelectedRowRecords([]);
    }
  }, [opened]);

  const [responseError, setResponseError] = useState<string>();
  const [form] = Form.useForm();
  const onCustomerSearch = (value: string) => {
    if (value) {
      getListCustomer(value)
        .onError(e => {
          defaultErrorHandling(e);
          return false;
        })
        .then(res => {
          setResponseError(undefined);
          setOption(
            (res.list ?? []).map(i => ({
              name: `${i.humanCode} ${i.name} ${i.cellphone}`,
              value: i.muid,
            }))
          );
        })
        .catch(e => {
          console.log(e);
          setResponseError(e.cause.errorMessage);
        })
        .finally(() => {
          form.validateFields(['search']);
        });
    }
  };

  const batchReassignSelectedRowKeys = useMemo(() => {
    if (!batchReassignSelectedRowRecords) return [];
    return batchReassignSelectedRowRecords.map(i => i.id);
  }, [batchReassignSelectedRowRecords]);

  const onBatchReassignSelectChange = (keys: Key[], slectRecords: StaffResponse[]) => {
    setBatchReassignSelectedRowRecords(slectRecords);
  };

  const children = (
    <>
      <Form form={form}>
        <Form.Item
          name="search"
          rules={[
            formInstance => ({
              validator(_: any, value: string) {
                console.log(responseError);
                if (responseError) {
                  return Promise.reject(responseError);
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <SearchInput
            style={{ width: 500 }}
            timer={500}
            onSearch={onCustomerSearch}
            placeholder="输入希望绑定到的用户手机号/完整用户ID/完整用户姓名"
            option={option}
            onChange={(value: string) => {
              setMuid(value);
            }}
            // autoComplete="off"
          />
        </Form.Item>
      </Form>
      <Table
        rowKey="id"
        rowSelection={{ selectedRowKeys: batchReassignSelectedRowKeys, onChange: onBatchReassignSelectChange }}
        dataSource={selectedRowRecords}
        columns={batchColumns}
      />
    </>
  );

  const onDisable = (record: StaffResponse) => {
    Modal.confirm({
      title: <p>禁用账号</p>,
      className: styles.modal,
      content: <div>即将禁用员工账号{record.customerUserName}, 点击确认以操作该执行</div>,
      onOk: () => {
        return disableBatchStaff([record.id])
          .then(res => {
            if (res.result) {
              return onSuccess(<div>已禁用账号{record.customerUserName}</div>);
            }
          })
          .then(() => {
            setSearchParams({ ...searchParams });
          });
      },
    });
  };

  const onFreeze = (record: StaffResponse) => {
    Modal.confirm({
      title: <p>冻结账号</p>,
      className: styles.modal,
      content: <div>即将冻结员工账号{record.customerUserName}, 点击确认以操作该执行</div>,
      onOk: () => {
        return freezeStaff(record.id)
          .then(res => {
            if (res.result) {
              return onSuccess(<div>已冻结账号{record.customerUserName}</div>);
            }
          })
          .then(() => {
            setSearchParams({ ...searchParams });
          });
      },
    });
  };

  const onEnable = (record: StaffResponse) => {
    Modal.confirm({
      title: <p>启用账号</p>,
      className: styles.modal,
      content: <div>即将启用员工账号{record.customerUserName}, 点击确认以操作该执行</div>,
      onOk: () => {
        return enableStaff(record.id)
          .then(res => {
            if (res.result) {
              return onSuccess(<div>已启用账号{record.customerUserName}</div>);
            }
          })
          .then(() => {
            setSearchParams({ ...searchParams });
          });
      },
    });
  };

  const showResetPasswordModal = (record: StaffResponse) => {
    Modal.confirm({
      title: '重置密码',
      icon: <ExclamationCircleFilled />,
      className: styles.modal,
      content: (
        <div>
          即将重置{record.customerUserName}（用户账号：{record.customerUserUsername}）密码, 点击确认以操作该执行
        </div>
      ),
      onOk: async () => {
        const res = await resetCustomerPassword({ muid: record.muid! });
        if (res.password) {
          return new Promise(resolve => {
            const modal = Modal.success({
              title: '重置成功！',
              className: styles.modal,
              content: (
                <div>
                  用户{record.customerUserName}（用户账号：{record.customerUserUsername}）的密码已经重置为：
                  {res.password}
                </div>
              ),
              footer: (
                <div className={styles.footerSty}>
                  <CopyToClipboard
                    text={res.password!}
                    onCopy={() => {
                      message.success('复制密码成功');
                    }}
                  >
                    <Button type="primary">复制新密码</Button>
                  </CopyToClipboard>
                  <Button
                    style={{ marginLeft: 8 }}
                    type="default"
                    onClick={() => {
                      modal.destroy();
                    }}
                  >
                    关闭
                  </Button>
                </div>
              ),
            });
          });
        }
      },
    });
  };

  const renderAction = (value: undefined, record: StaffResponse) => {
    return (
      <Space size={16}>
        <Button
          type="link"
          onClick={() => {
            navigate(`/system/customer/staff/detail/${record.id}`);
          }}
        >
          查看
        </Button>
        <Button
          type="link"
          onClick={() => {
            navigate(`/system/customer/staff/update/${record.id}`);
          }}
        >
          编辑
        </Button>
        {passwordOpSwitch === PassWordIsOpen.OPEN && (
          <Button type="link" onClick={() => showResetPasswordModal(record)}>
            重置密码
          </Button>
        )}
        {record.status === UserStatus.ENABLE ? (
          <>
            <Button
              type="link"
              onClick={() => {
                onDisable(record);
              }}
            >
              禁用
            </Button>
            <Button
              type="link"
              onClick={() => {
                onFreeze(record);
              }}
            >
              冻结
            </Button>
          </>
        ) : (
          <Button
            type="link"
            onClick={() => {
              onEnable(record);
            }}
          >
            启用
          </Button>
        )}
        <Button
          type="link"
          onClick={() => {
            setSelectedRowRecords([record]);
            onBatchReassignStaff(false);
          }}
        >
          绑定给他人
        </Button>
        {hasStaffOuMange && (
          <Button
            type="link"
            onClick={() => {
              navigate(`/system/customer/staff/ou/${record.id}`);
            }}
          >
            运营单元
          </Button>
        )}
      </Space>
    );
  };

  const onBatchReassignStaffOk = () => {
    if (!muid) {
      message.warning('请先选择用户!');
      return;
    }
    if (!batchReassignSelectedRowKeys.length) {
      message.warning('请先勾选需要换绑的员工!');
      return;
    }
    setPasswordOpened(true);
  };

  const onPasswordOk = async () => {
    if (operationPassword && batchReassignSelectedRowKeys.length && muid) {
      try {
        await batchReassignStaff({
          muid: muid,
          staffIds: batchReassignSelectedRowKeys,
          operationPassword: operationPassword,
        }).then(res => {
          if (res.result) {
            setOperationPassword('');
            setSelectedRowKeys([]);
            setBatchReassignSelectedRowRecords([]);
            setPasswordOpened(false);
            setOpened(false);
            setOption([]);
            setSearchParams({ ...searchParams });
            return onSuccess(<div>绑定成功</div>);
          }
        });
      } catch (e: any) {
        message.error(e.errorMessage);
      }
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <Space className={styles.operationArea}>
        <Button
          type="primary"
          onClick={() => {
            navigate('/system/customer/staff/create');
          }}
          icon={<PlusOutlined />}
        >
          新建员工
        </Button>
        <Button disabled={batchButtonDisable} onClick={onDisableBatch}>
          批量禁用员工
        </Button>
        <Button
          disabled={!selectedRowKeys.length}
          onClick={() => {
            onBatchReassignStaff();
          }}
        >
          批量绑定到其他用户
        </Button>
      </Space>
      <Table
        rowKey="id"
        scroll={{ x: 1900 }}
        columns={[
          ...columns,
          {
            title: '操作',
            width: passwordOpSwitch === PassWordIsOpen.OPEN ? 390 : 345,
            fixed: 'right' as const,
            render: renderAction,
          },
        ]}
        loading={loading}
        dataSource={dataSource}
        rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
      />
      <Paging pagingInfo={pagingInfo} />
      <Modal
        width={800}
        title={<span style={{ color: 'red' }}>变更员工账号所绑定的账户</span>}
        children={children}
        open={opened}
        onCancel={() => setOpened(false)}
        onOk={onBatchReassignStaffOk}
      />
      <Modal
        width={350}
        title="请输入关键操作密码"
        children={
          <Space direction="vertical" style={{ width: '100%', padding: '20px 0' }}>
            <Input.Password
              autoComplete="new-password"
              value={operationPassword}
              placeholder="请输入操作密码"
              onChange={e => {
                setOperationPassword(e.target.value);
              }}
            />
            <a target="_blank" href="/user/settings/operatePassword">
              忘记密码或是未设置？
            </a>
          </Space>
        }
        open={passwordOpened}
        onCancel={() => setPasswordOpened(false)}
        onOk={onPasswordOk}
      />
    </Wrapper>
  );
};

export default Employee;
