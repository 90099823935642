import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  FormTitle,
  Form,
  Input,
  Select,
  Radio,
  SubContent,
} from '@maxtropy/components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, TreeSelect, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { getOrganizationWithCodeWithCurrent, OrganizationTreeProps } from '../../../api/customer';
import { BusinessScope, BusinessScopeDisplay } from '../../../api/const';
import ShowInput from '../../../components/ShowInput';
import {
  createPropertyManagementConfig,
  getPropertyManagementConfig,
  PropertyManagementConfigResponse,
  updatePropertyManagementConfig,
} from '../../../api/propertyManagementConfig';
import SelectPropMgmtDeptBusinessStaff from '../components/PropMgmtDeptBusinessStaff';
import { getTaxPoint, TaxPointRespnse } from '../../../api/taxPoint';
import { getPowerTierConfigList, PowerTierConfigResponse } from '../../../api/powerFactorConfig';
import PowerFactorConfigModal from '../components/PowerFactorConfigModal';
import CheckBusinessScope from '../components/CheckBusinessScope';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const formatTreeData = (data: OrganizationTreeProps[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    return res;
  });
};

interface PropMgmtDeptFormProps extends Omit<PropertyManagementConfigResponse, 'id' | 'code' | 'customerName'> {}

const CreatePropMgmtDept: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<PropMgmtDeptFormProps>();
  const navigate = useNavigate();

  const [mcid, setMcid] = useState<string>();
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);

  const [powerTierConfigs, setPowerTierConfigs] = useState<Array<PowerTierConfigResponse>>([]);
  const [powerTierConfigId, setPowerTierConfigId] = useState<number>();

  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getPowerTierConfigList().then(res => setPowerTierConfigs(res.list ?? []));
  }, []);

  useEffect(() => {
    if (mcid) {
      form.resetFields([
        ['electricityDistributionSalesConfig', 'opStaffList'],
        ['propertyManagementNewEnergyConfig', 'opStaffList'],
      ]);
    }
  }, [mcid, form]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}物业管理配置` }];
  }, [isEdit]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, []);

  const [data, setData] = useState<PropertyManagementConfigResponse>();

  useEffect(() => {
    if (id) {
      getPropertyManagementConfig(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        customerMcid: data.customerName,
      });
    }
  }, [data, form]);

  const [taxPoints, setTaxPoints] = useState<TaxPointRespnse[]>([]);

  useEffect(() => {
    getTaxPoint().then(res => setTaxPoints(res.list ?? []));
  }, []);

  const onFinish = (value: PropMgmtDeptFormProps) => {
    const formValue = {
      salesOrg: value.salesOrg,
      invoiceAddress: value.invoiceAddress,
      taxId: value.taxId,
      beneficialBank: value.beneficialBank,
      bankAccount: value.bankAccount,
      businessScopes: value.businessScopes,
      electricityDistributionSalesConfig: value.electricityDistributionSalesConfig && {
        contactNumber: value.electricityDistributionSalesConfig.contactNumber,
        taxPoint: value.electricityDistributionSalesConfig.taxPoint,
        powerTierConfigId: value.electricityDistributionSalesConfig.powerTierConfigId,
        opStaffList: (value.electricityDistributionSalesConfig.opStaffList ?? []).map(i => ({
          id: i.id ?? undefined,
          staffId: i.staffId,
          workDivision: i.workDivision,
          status: i.status,
          description: i.description,
        })),
        billTitle: value.electricityDistributionSalesConfig.billTitle,
      },
      propertyManagementNewEnergyConfig: value.propertyManagementNewEnergyConfig && {
        companyName: value.propertyManagementNewEnergyConfig.companyName,
        companyAddress: value.propertyManagementNewEnergyConfig.companyAddress,
        contactNumber: value.propertyManagementNewEnergyConfig.contactNumber,
        taxPoint: value.propertyManagementNewEnergyConfig.taxPoint,
        opStaffList: (value.propertyManagementNewEnergyConfig.opStaffList ?? []).map(i => ({
          id: i.id ?? undefined,
          staffId: i.staffId,
          workDivision: i.workDivision,
          status: i.status,
          description: i.description,
        })),
        billTitle: value.propertyManagementNewEnergyConfig.billTitle,
      },
    };
    if (isEdit) {
      setLoading(true);
      // 编辑
      if (data) {
        updatePropertyManagementConfig({
          id: data.id,
          ...formValue,
        })
          .then(() => {
            goList();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      // 新建
      setLoading(true);
      createPropertyManagementConfig({
        ...formValue,
        customerMcid: value.customerMcid,
      })
        .then(() => {
          goList();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const goList = useCallback(() => {
    setLoading(false);
    navigate(`/system/operation/prop-mgmt-dept`);
  }, [navigate]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="资产管理信息配置" />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        {...formLayout}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
      >
        <SubContent style={{ marginBottom: 8 }}>
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item name="customerMcid" label="关联组织" rules={[{ required: true, message: '请选择关联组织' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <TreeSelect
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeDefaultExpandAll
                    onChange={val => {
                      setMcid(val);
                    }}
                    treeData={treeData}
                    placeholder="请选择"
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="salesOrg"
                label="销售单位"
                rules={[
                  { required: true, message: '请输入销售单位' },
                  {
                    pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-()（）.%]+$/,
                    message: '请输入中文、英文、数字，支持输入特殊字符-()（）.%',
                  },
                  { max: 50, message: '最多输入50个字' },
                ]}
              >
                <Input maxLength={50} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="invoiceAddress"
                label="开票地址"
                rules={[
                  { required: true, message: '请输入开票地址', whitespace: true },
                  { max: 100, message: '最多输入100个字' },
                ]}
              >
                <Input maxLength={100} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="taxId"
                label="纳税号"
                rules={[
                  { required: true, message: '请输入纳税号' },
                  { pattern: /^[a-zA-Z0-9]+$/, message: '请输入英文、数字' },
                  { max: 30, message: '最多输入30个字' },
                ]}
              >
                <Input maxLength={30} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="beneficialBank"
                label="开户行"
                rules={[
                  { required: true, message: '请输入开户行' },
                  { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文' },
                  { max: 50, message: '最多输入50个字' },
                ]}
              >
                <Input maxLength={50} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="bankAccount"
                label="账号"
                rules={[
                  { required: true, message: '请输入账号' },
                  { pattern: /^[0-9]+$/, message: '请输入数字' },
                  { max: 20, message: '最多输入20个字' },
                ]}
              >
                <Input maxLength={20} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={24} className={styles.col}>
              <Form.Item name="businessScopes" label="业务范围">
                <CheckBusinessScope
                  options={[
                    {
                      label: BusinessScopeDisplay[BusinessScope.ELECTRICITYDISTRIBUTIONSALES],
                      value: BusinessScope.ELECTRICITYDISTRIBUTIONSALES,
                    },
                    { label: BusinessScopeDisplay[BusinessScope.NEWENERGY], value: BusinessScope.NEWENERGY },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Form.Item noStyle dependencies={['businessScopes']}>
          {({ getFieldValue }) =>
            ((getFieldValue('businessScopes') ?? []) as BusinessScope[]).includes(
              BusinessScope.ELECTRICITYDISTRIBUTIONSALES
            ) && (
              <SubContent title={BusinessScopeDisplay[BusinessScope.ELECTRICITYDISTRIBUTIONSALES]}>
                <Row>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['electricityDistributionSalesConfig', 'billTitle']}
                      label="核查联表头"
                      rules={[
                        { required: true, message: '请输入核查联表头' },
                        {
                          pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-()（）.%]+$/,
                          message: '请输入中文、英文、数字，支持输入特殊字符-()（）.%',
                        },
                        { max: 50, message: '最多输入50个字' },
                      ]}
                    >
                      <Input maxLength={50} placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['electricityDistributionSalesConfig', 'contactNumber']}
                      label="供电服务热线"
                      rules={[
                        { required: true, message: '请输入供电服务热线' },
                        { pattern: /^[0-9-]+$/, message: '请输入数字，支持输入特殊字符-' },
                        { max: 20, message: '最多输入20个字' },
                      ]}
                    >
                      <Input maxLength={20} placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['electricityDistributionSalesConfig', 'taxPoint']}
                      label="税点"
                      rules={[{ required: true, message: '请选择税点' }]}
                    >
                      <Select placeholder="请选择" options={taxPoints.map(i => ({ label: i.name, value: i.value }))} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className={styles.col}>
                    <Form.Item label="功率因数调整电费比例" required>
                      <Space direction="vertical" size={0} style={{ width: '100%' }}>
                        <Form.Item
                          name={['electricityDistributionSalesConfig', 'powerTierConfigId']}
                          rules={[{ required: true, message: '请选择功率因数调整电费比例' }]}
                        >
                          <Radio.Group
                            onChange={e => {
                              setPowerTierConfigId(e.target.value);
                            }}
                            options={powerTierConfigs.map(i => ({ label: i.name, value: i.id }))}
                          />
                        </Form.Item>
                        <PowerFactorConfigModal powerTierConfigId={powerTierConfigId} />
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className={styles.col}>
                    <Form.Item name={['electricityDistributionSalesConfig', 'opStaffList']} wrapperCol={{ span: 24 }}>
                      <SelectPropMgmtDeptBusinessStaff mcid={mcid ?? data?.customerMcid} />
                    </Form.Item>
                  </Col>
                </Row>
              </SubContent>
            )
          }
        </Form.Item>
        <Form.Item noStyle dependencies={['businessScopes']}>
          {({ getFieldValue }) =>
            ((getFieldValue('businessScopes') ?? []) as BusinessScope[]).includes(BusinessScope.NEWENERGY) && (
              <SubContent title={BusinessScopeDisplay[BusinessScope.NEWENERGY]}>
                <Row>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['propertyManagementNewEnergyConfig', 'billTitle']}
                      label="核查联表头"
                      rules={[
                        { required: true, message: '请输入核查联表头' },
                        {
                          pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-()（）.%]+$/,
                          message: '请输入中文、英文、数字，支持输入特殊字符-()（）.%',
                        },
                        { max: 50, message: '最多输入50个字' },
                      ]}
                    >
                      <Input maxLength={50} placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['propertyManagementNewEnergyConfig', 'companyName']}
                      label="能源公司名称"
                      rules={[
                        { required: true, message: '请输入能源公司名称' },
                        {
                          pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-()（）.%]+$/,
                          message: '请输入中文、英文、数字，支持输入特殊字符-()（）.%',
                        },
                        { max: 50, message: '最多输入30个字' },
                      ]}
                    >
                      <Input maxLength={50} placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['propertyManagementNewEnergyConfig', 'companyAddress']}
                      label="能源公司地址"
                      rules={[
                        { required: true, message: '请输入能源公司地址', whitespace: true },
                        { max: 100, message: '最多输入100个字' },
                      ]}
                    >
                      <Input maxLength={100} placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['propertyManagementNewEnergyConfig', 'contactNumber']}
                      label="供电服务热线"
                      rules={[
                        { required: true, message: '请输入供电服务热线' },
                        { pattern: /^[0-9-]+$/, message: '请输入数字，支持输入特殊字符-' },
                        { max: 20, message: '最多输入20个字' },
                      ]}
                    >
                      <Input maxLength={20} placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={styles.col}>
                    <Form.Item
                      name={['propertyManagementNewEnergyConfig', 'taxPoint']}
                      label="税点"
                      rules={[{ required: true, message: '请选择税点' }]}
                    >
                      <Select placeholder="请选择" options={taxPoints.map(i => ({ label: i.name, value: i.value }))} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className={styles.col}>
                    <Form.Item name={['propertyManagementNewEnergyConfig', 'opStaffList']} wrapperCol={{ span: 24 }}>
                      <SelectPropMgmtDeptBusinessStaff mcid={mcid ?? data?.customerMcid} />
                    </Form.Item>
                  </Col>
                </Row>
              </SubContent>
            )
          }
        </Form.Item>
        <Space size={8} className="sticky-footer-left">
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
          <Button onClick={goList}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreatePropMgmtDept;
