import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Wrapper, useBreadcrumbRoutes, FormContent, Button, Modal, FormTitle } from '@maxtropy/components';
import { RoleSteps } from '../../../components/CommonSteps';
import { Col, Row, Space } from 'antd';
import { findRoleByCode, RoleInfo, updatePermission, updateRoleNavigationPermissions } from '../../../api/role';
import { useNavigate, useParams } from 'react-router-dom';
import usePageStatus, { PageStatus, PageStatusDisplay } from './usePageStatus';
import { ProfileOutlined, UserOutlined } from '@ant-design/icons';
import EditPermissions, { EditPermissionRefProps } from './EditPermissions';
import EditNavigationPermission, { EditNavigationPermissionRefProps } from './EditNavigationPermission';
import { apiV2RoleMiniAppPermissionsUpsertPost } from '@maxtropy/cc-customer-apis-v2';

const EditOutLayer: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const pageStatus = usePageStatus();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [toggleBtn, setToggleBtn] = useState<string>('导航视图');
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitAndLookLoading, setSubmitAndLookLoading] = useState(false);
  const navigationPermissionsRef = React.useRef<EditNavigationPermissionRefProps | null>(null);
  const permissionsRef = React.useRef<EditPermissionRefProps | null>(null);

  /* 角色名称，模版编号相关*/
  const [role, setRole] = useState<RoleInfo>();
  useEffect(() => {
    if (!code) return;
    if (pageStatus !== PageStatus.CREATE) {
      findRoleByCode(code).then(data => {
        setRole(data);
      });
    }
  }, [code, pageStatus]);
  /* 角色名称，模版编号相关*/

  const routes = [
    {
      name: `${
        pageStatus !== PageStatus.DETAIL ? PageStatusDisplay[PageStatus.UPDATE] : PageStatusDisplay[PageStatus.DETAIL]
      }角色权限`,
    },
  ];

  const disabled = pageStatus === PageStatus.DETAIL;

  const toggleView = (name: string) => {
    if (name === '功能视图') {
      setToggleBtn('功能视图');
      if (!disabled) submit('保存并查看');
    } else {
      setToggleBtn('导航视图');
      if (!disabled) submit('保存并查看');
    }
  };

  const changeRoute = () => {
    navigate(`/system/rolePer/role/permissions/bind/detail/${code}`);
  };

  const onClose = () => {
    if (!disabled) {
      Modal.confirm({
        content: '确认放弃未保存的修改返回列表吗?',
        onOk: () => {
          navigate(`/system/rolePer/role`);
        },
      });
    } else {
      navigate(`/system/rolePer/role`);
    }
  };

  // prettier-ignore
  const submit = async (mode: string) => { // NOSONAR
    if (mode === '下一步') {
      setSubmitLoading(true);
    } else {
      setSubmitAndLookLoading(true);
    }
    if (toggleBtn === '导航视图') {
      const navigationPermissions = navigationPermissionsRef.current?.submit();
      const miniAppnavigationPermissions = navigationPermissionsRef.current?.getSubmitMiniAppMenuData();

      if (miniAppnavigationPermissions && (miniAppnavigationPermissions.length > 0)) {
        await apiV2RoleMiniAppPermissionsUpsertPost({list:miniAppnavigationPermissions});
      }

      if (navigationPermissions) {
        await updateRoleNavigationPermissions(navigationPermissions);
        if (mode === '下一步') {
          setSubmitLoading(false);
          navigate(`/system/rolePer/role/permissions/bind/organization/${code}`);
        } else {
          setSubmitAndLookLoading(false);
        }
      }
    } else {
        const permissions = permissionsRef.current?.submit();
        const functionPermissions = permissionsRef.current?.getSubmitFunctionData();

        if (functionPermissions && functionPermissions.length > 0) {
          await apiV2RoleMiniAppPermissionsUpsertPost({list:functionPermissions});
        }

        if (!permissions) return;

        updatePermission(permissions)
        .then(() => {
          if (mode === '下一步') {
            navigate(`/system/rolePer/role/permissions/bind/organization/${code}`);
          }
        })
        .finally(() => {
          const loadingSetter = mode === '下一步' ? setSubmitLoading : setSubmitAndLookLoading;
          loadingSetter(false);
        });
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle
        title={`${
          pageStatus !== PageStatus.DETAIL ? PageStatusDisplay[PageStatus.UPDATE] : PageStatusDisplay[PageStatus.DETAIL]
        }角色权限`}
        style={{ marginBottom: 16 }}
      />
      <div className={styles.permissionsWrapper}>
        <RoleSteps current={1} isDetail={pageStatus === PageStatus.DETAIL} />
        <Row gutter={[16, 16]} className={styles.desc}>
          <Col>
            <UserOutlined />
            角色名称：{role?.name}
          </Col>
          <Col offset={1}>
            <ProfileOutlined />
            模版编号： {role?.code}
          </Col>
        </Row>
        {toggleBtn === '导航视图' && (
          <FormContent
            title={'导航视图'}
            extraContent={
              <Button loading={submitAndLookLoading} type="primary" onClick={() => toggleView('功能视图')}>
                {pageStatus === PageStatus.DETAIL ? '查看功能视图' : '保存并查看功能视图'}
              </Button>
            }
          >
            <EditNavigationPermission ref={navigationPermissionsRef} miniIds={role?.miniAppIds as number[]} />
          </FormContent>
        )}
        {toggleBtn === '功能视图' && (
          <FormContent
            title={'功能视图'}
            extraContent={
              <Button loading={submitAndLookLoading} type="primary" onClick={() => toggleView('导航视图')}>
                {pageStatus === PageStatus.DETAIL ? '查看导航视图' : '保存并查看导航视图'}
              </Button>
            }
          >
            <EditPermissions ref={permissionsRef} miniIds={role?.miniAppIds as number[]} />
          </FormContent>
        )}
        <div className={styles.buttonContainer}>
          <Space size={8}>
            <Button
              onClick={() => {
                navigate(`/system/rolePer/role/${pageStatus}/${code}`);
              }}
            >
              上一步
            </Button>
            <Button
              loading={submitLoading}
              type="primary"
              {...(disabled ? { onClick: changeRoute } : { onClick: () => submit('下一步') })}
            >
              下一步
            </Button>
            <Button onClick={onClose}>关闭</Button>
          </Space>
        </div>
      </div>
    </Wrapper>
  );
};

export default EditOutLayer;

// uniq2ObjMap Map<string, Object>
//          Map<平台权限字符串 | 集成应用id， Map<权限， 唯一值>>
// obj2uniqMap Map<string | number, Map<string, string>>
