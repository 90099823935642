import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './index.module.scss';
import { Key, RoleTree, TreeDataNode as DataNode, getRealUrl, getRolePermissions, Tabs } from '@maxtropy/components';
import { AppFunctionPermissionVo, getAppPermissionTree } from '../../../api/app';
import {
  getRoleIntegratedAppList,
  IntegratedApp,
  IntegratedAppPermission,
  queryPermissionList,
  UpdatePermissionBody,
} from '../../../api/role';
import { useParams } from 'react-router-dom';
import usePageStatus, { PageStatus } from './usePageStatus';

import EditIntegratedPermissions, { RefProps } from './EditIntegratedPermissions';
import { MiniAppPermissionFunctionTreeItem, MiniAppPermissionItem, UpdateAppPermissionBodyItem } from './api';
import {
  apiV2MiniAppPermissionFunctionalTreePost,
  apiV2RoleMiniAppPermissionListPost,
  V2RoleMiniAppPermissionListPostResponse,
} from '@maxtropy/cc-customer-apis-v2';

interface EditPermissionsProps {
  miniIds: number[];
}

type RoleMiniAppPermissionItem = Exclude<V2RoleMiniAppPermissionListPostResponse['list'], undefined>[number];

export interface EditPermissionRefProps {
  submit: () => UpdatePermissionBody;
  getSubmitFunctionData: () => UpdateAppPermissionBodyItem[];
}

function dig(data: AppFunctionPermissionVo[], level = 0): DataNode[] {
  return data.map(item => {
    const isLeaf = !(Array.isArray(item.children) && item.children.length > 0);
    const type = item.code?.at(0) === 'b' ? '按钮权限' : '页面权限';
    const node = {
      children: [] as DataNode[],
      isLeaf,
      key: item.code,
      title: item.name,
      customized: !item.standard,
      type,
      pic: getRealUrl(item.pic),
      description: item.description,
    };
    if (!isLeaf) {
      node.children = dig(item.children!, level + 1);
    }
    return node;
  });
}

// 原始树结构转为组件的树结构
function convertToTreeData(originalTreeData: MiniAppPermissionFunctionTreeItem[]): DataNode[] {
  const nodes = originalTreeData.map(item => {
    const node: DataNode = {
      children: [] as DataNode[],
      key: item.code as Key,
      title: item.name,
      description: item.description,
      level: item.level,
      code: item.code,
      miniAppId: item.miniAppId,
    };
    if (item.children?.length) {
      node.children = convertToTreeData(item.children);
    }
    return node;
  });
  return nodes;
}

// 根据 key 获取原始树结构中的节点
function getOriginalTreeItemByKey(
  originalTreeData: MiniAppPermissionFunctionTreeItem[],
  key: string
): MiniAppPermissionFunctionTreeItem | undefined {
  for (const item of originalTreeData) {
    if (item.code === key) {
      return item;
    }
    if (item.children?.length) {
      let found = getOriginalTreeItemByKey(item.children, key);
      if (found) return found;
    }
  }
}

const EditPermissions: ForwardRefRenderFunction<EditPermissionRefProps, EditPermissionsProps> = (props, ref) => {
  const { miniIds } = props;
  const { code } = useParams<{ code: string }>();

  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [checkedFunctionItems, setCheckedFunctionItems] = useState<RoleMiniAppPermissionItem[]>();

  const [autoSyncedKeys, setAutoSyncedKeys] = useState<Key[]>([]);
  const [autoSyncedFunctionItems, setAutoSyncedFunctionItems] = useState<MiniAppPermissionItem[]>([]);

  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [originalFunctionalTreeData, setOriginalFunctionTreeData] = useState<MiniAppPermissionFunctionTreeItem[]>([]);

  const pageStatus = usePageStatus();
  const originalData = useRef<{ originalCheckedKeys: Key[]; originalAutoSyncedKeys: Key[] }>();

  const refMap = React.useRef(new Map<number, RefProps>());
  const [integratedApps, setIntegratedApps] = useState<IntegratedApp[]>([]);

  useEffect(() => {
    if (!code) return;
    getRoleIntegratedAppList(code).then(res => {
      setIntegratedApps(res.list ?? []);
    });
  }, [code]);

  useEffect(() => {
    if (!code) return;
    getAppPermissionTree().then(data => {
      const treeData = dig(data.list);
      setTreeData(treeData);
      queryPermissionList(code).then(data => {
        const { permissions = [], syncs = [] } = data;
        setCheckedKeys(permissions);
        setAutoSyncedKeys(syncs);
        originalData.current = { originalCheckedKeys: permissions, originalAutoSyncedKeys: syncs };
      });
    });

    if (miniIds.length > 0) {
      apiV2MiniAppPermissionFunctionalTreePost({ roleCode: code }).then(res => {
        setOriginalFunctionTreeData(res.list ?? []);
        apiV2RoleMiniAppPermissionListPost({ roleCode: code }).then(res => {
          setCheckedFunctionItems(res.list ?? []);
        });
      });
    }
  }, [code, miniIds]);

  const functionTreeData: DataNode[] = useMemo(() => {
    return convertToTreeData(originalFunctionalTreeData);
  }, [originalFunctionalTreeData]);

  const checkedFunctionKeys: Key[] = useMemo(() => {
    return checkedFunctionItems?.map(item => item.permissionCode!) ?? [];
  }, [checkedFunctionItems]);

  const autoSyncedFunctionKeys: Key[] = useMemo(() => {
    return autoSyncedFunctionItems.map(item => item.permissionCode!);
  }, [autoSyncedFunctionItems]);

  const setCheckedFunctionKeys = (keys: Key[]) => {
    const foundList = keys.map(key => {
      return getOriginalTreeItemByKey(originalFunctionalTreeData, key as string);
    });
    const checkedItems = foundList.map(item => {
      return {
        permissionCode: item?.code,
      };
    });
    setCheckedFunctionItems(checkedItems);
  };

  const setAutoSyncedFunctionKeys = (keys: Key[]) => {
    const foundList = keys.map(key => {
      return getOriginalTreeItemByKey(originalFunctionalTreeData, key as string);
    });
    const autoSyncedItems = foundList.map(item => {
      return {
        permissionCode: item?.code,
      };
    });
    setAutoSyncedFunctionItems(autoSyncedItems);
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: () => {
        const integratedAppPermission = Array.from(refMap.current.entries()).reduce((previousValue, [key, ref]) => {
          previousValue[key] = ref.submit();
          return previousValue;
        }, {} as { [key: number]: IntegratedAppPermission });
        const { permissions, syncs } = getRolePermissions({
          treeData,
          checkedKeys,
          autoSyncedKeys,
          ...originalData.current!,
        });
        return {
          roleCode: code!,
          addPermissions: permissions[0],
          deletePermission: permissions[1],
          addSyncs: syncs[0],
          deleteSyncs: syncs[1],
          integratedAppPermission,
        };
      },
      getSubmitFunctionData: () => {
        if (!functionTreeData.length) return [];
        let data = {
          roleCode: code,
          miniAppId: functionTreeData[0].miniAppId as number,
          permissions: checkedFunctionKeys as string[],
          syncPermissions: autoSyncedFunctionKeys as string[],
        };
        return [data];
      },
    }),
    [treeData, checkedKeys, autoSyncedKeys, code, functionTreeData, checkedFunctionKeys, autoSyncedFunctionKeys]
  );

  const disabled = pageStatus === PageStatus.DETAIL;

  return (
    <Tabs defaultActiveKey="platform" style={{ width: 1000 }}>
      <Tabs.TabPane tab="平台权限" key="platform">
        <div className={styles.treeContainer}>
          <h3>权限功能管理</h3>
          <RoleTree
            treeData={treeData}
            className={styles.treeWrapper}
            checkedKeys={checkedKeys}
            onCheck={setCheckedKeys}
            autoSyncedKeys={autoSyncedKeys}
            onAutoSync={setAutoSyncedKeys}
            autoSyncable
            showDetail
            disabled={disabled}
            renderHoverContent={data => (
              <div style={{ width: '320px' }}>
                <p className={styles.toolTips_P1}>权限名：{data.title}</p>
                <p className={styles.toolTips_P1}>权限类型：{data.type}</p>
                <p className={styles.toolTips_P2}>权限说明：{data.description}</p>
              </div>
            )}
          />
        </div>
      </Tabs.TabPane>
      {integratedApps.map(i => (
        <Tabs.TabPane tab={i.name} key={i.id}>
          <EditIntegratedPermissions
            ref={(ref: RefProps) => {
              refMap.current.set(i.id, ref);
            }}
            integratedAppId={i.id}
            roleCode={code!}
            disabled={disabled}
          />
        </Tabs.TabPane>
      ))}
      {miniIds.length > 0 && (
        <>
          <Tabs.TabPane tab={'小程序'} key="mini">
            <div className={styles.treeContainer}>
              <div className={styles.treeContainer}>
                <h3>权限功能管理</h3>
                <RoleTree
                  className={styles.treeWrapper}
                  treeData={functionTreeData}
                  checkedKeys={checkedFunctionKeys}
                  onCheck={setCheckedFunctionKeys}
                  autoSyncedKeys={autoSyncedFunctionKeys}
                  onAutoSync={setAutoSyncedFunctionKeys}
                  autoSyncable
                  showDetail
                  disabled={disabled}
                />
              </div>
            </div>
          </Tabs.TabPane>
        </>
      )}
    </Tabs>
  );
};

export default forwardRef(EditPermissions);
