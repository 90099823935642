import {
  TreeSelect,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Table,
  usePaging,
  CustomFilter,
} from '@maxtropy/components';
import { ModalProps, Row } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OuStaffType, OuStaffTypeDisplay, PageResponse } from '../../const';
import { useQuery } from '../../utils/utils';
import { CommonUserInfo, OrganizationResponse } from './interface';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { apiV2StaffPagePost } from '@maxtropy/cc-customer-apis-v2';
import { getOrganizationWithCodeWithCurrent } from '../../../../api/customer';
interface StaffSelectProps extends Omit<ModalProps, 'onOk'> {
  type: OuStaffType;
  dataSource: (...args: any[]) => Promise<PageResponse<CommonUserInfo>>;
  selectedStaffCodes: string[];
  disabledStaffCodes?: string[];
  onOk: (value: CommonUserInfo[]) => void;
  selectedRowKeys?: any;
  setSelectedRowKeys: (val: any) => void;
  selectedRows?: any;
  setSelectedRows: (val: any) => void;
  setSelectedPageRows: (val: any) => void;
  selectedPageRows?: any;
}

interface SearchParams {
  name?: string;
  staffCode?: string;
  cellphone?: string;
  username?: string;
  humanCode?: string;
  mcids?: string[];
}

const columns: ColumnType<CommonUserInfo>[] = [
  {
    title: '用户ID',
    dataIndex: 'customerUserHumanCode',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '用户登录账号',
    dataIndex: 'customerUserUsername',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '姓名',
    dataIndex: 'customerUserName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'customerUserCellphone',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const StaffSelect: React.FC<StaffSelectProps> = props => {
  const {
    type,
    dataSource,
    onOk,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    selectedPageRows,
    setSelectedPageRows,
    ...rest
  } = props;
  const title = `${OuStaffTypeDisplay[type]}选择`;
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2StaffPagePost({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total as number);
          }
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  useEffect(() => {
    if (props.visible) {
      setSelectedRowKeys(props.selectedStaffCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const afterClose = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    form.resetFields();
    onReset();
  };

  const onFinish = (val: any) => {
    const params = {
      name: val.name ? val.name : undefined,
      staffCode: val.staffCode ? val.staffCode : undefined,
      cellphone: val.cellphone ? val.cellphone : undefined,
      username: val.username ? val.username : undefined,
      humanCode: val.humanCode ? val.humanCode : undefined,
      mcids: val.mcids ? [val.mcids] : undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    const params: SearchParams = {
      name: undefined,
      staffCode: undefined,
      cellphone: undefined,
      username: undefined,
      humanCode: undefined,
      mcids: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const handleOk = () => {
    setSelectedPageRows([...selectedPageRows, ...selectedRows]);
    const data = selectedRows
      .filter(Boolean)
      .filter((item: CommonUserInfo) => !(props.selectedStaffCodes ?? []).includes(item.staffCode as string))
      .map((item: CommonUserInfo) => ({
        ...item,
        staffType: type,
        id: item.id,
      }));
    props.onOk?.(data);
  };

  const rowSelection = {
    type: 'checkbox' as const,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(selectedRowKeys);
      console.log(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: CommonUserInfo) => ({
      disabled: (props.disabledStaffCodes
        ? [...props.disabledStaffCodes, ...props.selectedStaffCodes]
        : props.selectedStaffCodes
      ).includes(record.staffCode as string),
    }),
    preserveSelectedRowKeys: true,
  };
  const [organization, setOrganition] = useState<any>();

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  return (
    <Modal title={title} size="big" onOk={handleOk} afterClose={afterClose} {...rest} bodyScroll>
      <CustomFilter form={form} onFinish={onFinish} onReset={onReset} colSpan={8}>
        <Form.Item name="name" label="姓名">
          <Input placeholder={'请输入姓名查询'} />
        </Form.Item>
        <Form.Item name="humanCode" label="用户ID">
          <Input placeholder={'请输入用户ID查询'} />
        </Form.Item>
        <Form.Item name="staffCode" label="员工工号">
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item name="cellphone" label="手机号">
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item name="username" label="用户登录账号">
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item name="mcids" label="所在组织">
          <TreeSelect
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeDefaultExpandAll
            treeData={treeData}
            placeholder="请选择"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </CustomFilter>

      <Row>
        <Table
          showSelectedCount
          selectedCount={selectedRowKeys.length}
          style={{ marginTop: 10 }}
          rowSelection={rowSelection}
          dataSource={data}
          scroll={{ y: 340 }}
          loading={isLoading}
          rowKey="staffCode"
          columns={columns}
        >
          <Paging pagingInfo={pagingInfo} />
        </Table>
      </Row>
    </Modal>
  );
};

export default StaffSelect;
