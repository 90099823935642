import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserStatus } from '../../../api/const';
import {
  getRole,
  getCustomerTree,
  getStaffCode,
  checkStaffCodeUnique,
  createStaff,
  updateStaff,
  fetchStaffById,
  CustomerUser,
} from '../../../api/staff';
import { RoleInfo } from '../../../api/role';
import { staffCodeRegex } from '../../../utils/regex';
import {
  Radio,
  Select,
  message,
  Form,
  Input,
  Button,
  ResponseCodeError,
  useAsync,
  useSubmission,
  SubContent,
} from '@maxtropy/components';
import { transformTree } from '../../RolePermissions/Bind/OrganizationTree';
import usePageStatus, { PageStatus } from '../../RolePermissions/Edit/usePageStatus';
import ChooseOrganizationFormItem from './ChooseOrganizationFormItem';
import styles from './index.module.scss';

interface StaffFormValues {
  mcid: string;
  staffCode: string | number;
  workEmail?: string;
  workWechat?: string;
  workQq?: string;
  roleId?: number;
  status: UserStatus;
}

interface CreateStaffProps {
  createStaffForm: FormInstance;
  createUserRes: CustomerUser | undefined;
  onClose: () => void;
}

const CreateStaff: React.FC<CreateStaffProps> = props => {
  const { createStaffForm: form, onClose, createUserRes } = props;

  const [selectedRole, setSelectedRole] = useState<RoleInfo>();
  const [selectedMcid, setSelectedMcid] = useState<string>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [doSubmit, submitting] = useSubmission();
  const pageStatus = usePageStatus();

  const organizationTreeData = useAsync(
    useCallback(async () => {
      const data = await getCustomerTree();
      if (data) {
        return transformTree([data]);
      } else {
        return [];
      }
    }, [])
  );

  const getRoleRequest = useCallback(async () => {
    if (selectedMcid) {
      return await getRole(selectedMcid);
    }
    return undefined;
  }, [selectedMcid]);

  const roles = useAsync(getRoleRequest);

  useEffect(() => {
    (async () => {
      if (!isNil(id)) {
        const res = await fetchStaffById(id);
        if (res) {
          const target = (roles?.list ?? []).find(item => item.id === res.roleId);
          setSelectedRole(target);
          setSelectedMcid(res.mcid);
          form.setFieldsValue({
            ...res,
          });
        }
      }
    })();
  }, [form, id, roles]);

  useEffect(() => {
    (async () => {
      if (pageStatus === PageStatus.CREATE && selectedMcid) {
        const res = await getStaffCode();
        form.setFieldsValue({
          staffCode: res.staffCode,
        });
      }
    })();
  }, [form, pageStatus, selectedMcid]);

  const staffCodeValidator = async (rule: any, value: string) => {
    if (!staffCodeRegex.test(value)) {
      return Promise.reject('请输入：字母+数字，最多12位');
    } else {
      const res = await checkStaffCodeUnique({
        staffCode: value,
      });
      if (res.result) {
        return Promise.reject('员工工号已被使用！');
      }
      return Promise.resolve();
    }
  };

  const onFinish = async (values: StaffFormValues) => {
    try {
      await doSubmit(async () => {
        if (createUserRes && createUserRes.muid) {
          const params = {
            ...values,
            muid: createUserRes.muid,
          };
          const response =
            pageStatus === PageStatus.CREATE ? await createStaff(params) : await updateStaff({ ...params, id: id! });
          if (response) {
            navigate(`/system/customer/staff/authority/update/${response.id}`);
          }
        }
      });
    } catch (e) {
      console.error(e);
      message.error(ResponseCodeError.getMessage(e, '保存失败'));
    }
  };

  const onValuesChange = (changedValues: Record<string, any>) => {
    const { roleId } = changedValues;
    if (!isNil(roleId)) {
      const target = (roles?.list ?? []).find(item => item.id === roleId);
      setSelectedRole(target);
    } else {
      setSelectedRole(undefined);
    }
  };

  const disabled = pageStatus === PageStatus.DETAIL || !createUserRes;
  const isEdit = pageStatus === PageStatus.UPDATE;

  return (
    <SubContent title="工号、组织和角色">
      <Form
        form={form}
        layout="vertical"
        validateTrigger={['onBlur']}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span={8}>
            <Form.Item label="选择组织" name="mcid" rules={[{ required: true, message: '请选择组织' }]}>
              <ChooseOrganizationFormItem
                disabled={disabled || isEdit}
                treeData={organizationTreeData || []}
                onChange={value => {
                  setSelectedMcid(value);
                  form.setFieldsValue({
                    roleId: undefined,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="员工工号（不用于登录、用于企业内标识区分）"
              name="staffCode"
              rules={[
                { required: true, message: '请输入员工工号' },
                { validator: pageStatus === PageStatus.CREATE ? staffCodeValidator : undefined },
              ]}
            >
              <Input placeholder="请输入员工工号" disabled={!selectedMcid || disabled || isEdit} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="工作微信"
              name="workWechat"
              rules={[{ pattern: /^[a-zA-Z0-9_-]{6,20}?$/, message: '请输入正确的微信' }]}
            >
              <Input placeholder="请输入工作微信" disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="工作邮箱" name="workEmail" rules={[{ type: 'email', message: '请输入正确的邮箱' }]}>
              <Input placeholder="请输入工作邮箱" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="工作QQ" name="workQq" rules={[{ pattern: /^\d{4,15}?$/, message: '请输入正确的QQ' }]}>
              <Input placeholder="请输入工作QQ" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="员工工号状态"
              name="status"
              validateFirst
              rules={[{ required: true, message: '请选择员工工号状态' }]}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={UserStatus.ENABLE}>启用</Radio>
                <Radio value={UserStatus.DISABLE}>禁用</Radio>
                <Radio value={UserStatus.FREEZE}>冻结</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label="角色"
              extra={
                <div className={styles.tips}>
                  <InfoCircleOutlined />
                  不分配角色则用户登录并选择此工号后无法访问除用户中心之外的页面
                </div>
              }
            >
              <span
                style={{
                  position: 'absolute',
                  top: -30,
                  right: 0,
                }}
              >
                <Button
                  size="small"
                  type="link"
                  style={{
                    display: !isNil(selectedRole) ? 'block' : 'none',
                  }}
                >
                  <Link to={`/system/rolePer/role/detail/${selectedRole?.code}`} target="_blank">
                    查看该角色的权限
                  </Link>
                </Button>
              </span>
              <Form.Item name="roleId" noStyle>
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择角色"
                  allowClear
                  showSearch
                  filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  disabled={!selectedMcid || disabled}
                  options={(roles?.list ?? []).map(item => ({ label: item.name, value: item.id }))}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        {!!createUserRes && (
          <Row className={styles.buttonWrapper}>
            <Col span={8}>
              <Form.Item>
                <Space>
                  {pageStatus === PageStatus.DETAIL ? (
                    <Button type="primary">
                      <Link to={`/system/customer/staff/authority/detail/${id}`}>下一步</Link>
                    </Button>
                  ) : (
                    <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
                      下一步
                    </Button>
                  )}
                  <Button onClick={onClose}>关闭</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </SubContent>
  );
};

export default CreateStaff;
