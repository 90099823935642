import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { CurrentDataProps } from './Manage';
import {
  CustomerProps,
  getCustomer,
  getNextLevelName,
  getSummary,
  moveCustomer,
  MoveCustomerProps,
  OrganizationTreeProps,
  SummaryProps,
} from '../../../api/customer';
import styles from '../index.module.scss';
import { Upload, CustomerAndUser, Form, Input, message, Modal, TreeSelect } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getChildren } from '../../../utils/helper';
import { checkFullName, checkName } from './utils';

interface MoveModalProps {
  visible: boolean;
  onCancel: () => void;
  currentData?: CurrentDataProps;
  treeData: OrganizationTreeProps[];
  user?: CustomerAndUser;
}

interface TreeSelectProps extends OrganizationTreeProps {
  value: string;
  title: string;
  children: TreeSelectProps[];
  disabled?: boolean;
}

const MoveModal: React.FC<MoveModalProps> = props => {
  const { currentData, onCancel, treeData, user } = props;
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [opened, setOpened] = useState<boolean>(props.visible);
  const [logoMcid, setLogoMcid] = useState<string | undefined>('');
  const [step, setStep] = useState<1 | 2>(1);
  const [values, setValues] = useState<MoveCustomerProps>();
  const [info, setInfo] = useState<SummaryProps>();
  const [customerInfo, setCustomerInfo] = useState<CustomerProps>();

  useEffect(() => {
    getCustomer(currentData!.mcid).then(data => {
      setCustomerInfo(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatTreeData = (data: OrganizationTreeProps[], parentList?: string[]) => {
    return data.map(item => {
      const res: TreeSelectProps = {
        ...item,
        value: item.data!.mcid,
        title: `${!item.data?.hasPermission ? '' : `${item.data?.name}（${item.data?.levelName}）`}`,
        disabled: parentList?.includes(item.data!.mcid) || !item.data?.hasPermission,
        children: formatTreeData(item.children, parentList),
      };
      return res;
    });
  };

  useEffect(() => {
    if (logoMcid) {
      getCustomer(logoMcid).then(data => {
        form.resetFields(['logoUrl']);
        form.setFieldsValue({
          logoUrl: data.logoUrl,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoMcid]);

  const list = useMemo(() => {
    if (currentData) {
      const childrenList = getChildren(treeData, currentData.mcid);
      return formatTreeData(treeData, childrenList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const onFinish = (data: MoveCustomerProps) => {
    data.source = currentData!.mcid;
    data.muid = user!.customerUser.muid;
    setValues({ ...values, ...data });
    getSummary(currentData!.mcid).then(data => {
      setInfo(data);
    });
    setStep(2);
  };

  const stepTwoOnFinish = async (value: { operationPassword: string }) => {
    try {
      await moveCustomer({ ...values, operationPassword: value.operationPassword }).then(data => {
        setOpened(false);
        onCancel();
      });
    } catch (e: any) {
      message.error(e.errorMessage);
    }
  };

  return (
    <>
      <Modal
        title="移动组织"
        width={step === 1 ? 800 : 500}
        open={opened}
        cancelText={step === 1 ? '取消' : '返回上一步'}
        onOk={() => (step === 1 ? form.submit() : passwordForm.submit())}
        maskClosable={false}
        onCancel={() => {
          if (step === 1) {
            setOpened(false);
            onCancel();
          } else {
            setStep(1);
          }
        }}
      >
        {step === 1 ? (
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={[32, 0]}>
              {currentData?.parentName && (
                <Col span={12}>
                  <Form.Item label="原上级组织">
                    <span>{currentData.parentName}</span>
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item label="原组织层级">
                  <span>{`${currentData?.name}（${currentData?.levelName}）`}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="目标上级组织"
                  name="target"
                  rules={[{ required: true, message: '请选择目标上级组织' }]}
                >
                  <TreeSelect
                    treeData={list}
                    treeDefaultExpandAll
                    dropdownMatchSelectWidth={false}
                    onSelect={(v: any, node: any) => {
                      setLogoMcid(v);
                      getNextLevelName(v).then(data => {
                        form.setFieldsValue({ showTarget: data.levelName });
                        setValues({
                          ...values,
                          targeCode: node.data.code,
                          targetName: node.data.name,
                          targetLevelName: data.levelName,
                        });
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="移动后组织层级" name="showTarget">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="组织简称"
                  initialValue={currentData?.name}
                  required
                  rules={[{ validator: (rule: any, value: string) => checkName(rule, value, customerInfo?.name) }]}
                >
                  <Input placeholder="最多8个字符，租户级唯一" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="fullName"
                  label="组织全称"
                  initialValue={currentData?.fullName}
                  required
                  rules={[
                    { validator: (rule: any, value: string) => checkFullName(rule, value, customerInfo?.fullName) },
                  ]}
                >
                  <Input placeholder="最多30个字符，租户级唯一" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="组织logo">
                  <div className={styles.box}>
                    <Form.Item name="logoUrl" noStyle>
                      <Upload />
                    </Form.Item>
                  </div>
                </Form.Item>
                <span className={styles.restLogo} onClick={() => setLogoMcid(currentData?.mcid)}>
                  还原为默认logo
                </span>
              </Col>
            </Row>
          </Form>
        ) : (
          <>
            <p style={{ color: 'rgba(255,255,255,0.65)' }}>
              您即将把组织名{currentData?.name}， CODE[{currentData?.code}]从组织名{currentData?.parentName}， CODE[
              {currentData?.parentCode}]下移动到组织名{values?.targetName}， CODE[{values?.targeCode}
              ]下，新的组合层级变为{values?.targetLevelName}(组织层级名)， 由此造成{info?.allStaffsSum ?? 0}
              个员工工号下的数据权限失效
              {/*，共计{info?.projectSum ?? 0}个项目也一同移动到组织名{values?.targetName}，CODE[{values?.targeCode}]下*/}
            </p>
            <p className={styles.gray}>
              <InfoCircleOutlined style={{ color: '#FAAD14' }} />
              不归属于该组织，而是由上级组织授权给该组织使用的角色，在组织移动后全部解绑
            </p>
            <Form form={passwordForm} onFinish={stepTwoOnFinish}>
              <Form.Item
                name="operationPassword"
                label="操作密码"
                rules={[{ required: true, message: '请输入操作密码' }]}
                extra={
                  <span>
                    没有设置操作密码？ 点击前往
                    <a href={'/user/settings/operatePassword'} target="_blank" rel="noreferrer">
                      设置
                    </a>
                  </span>
                }
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default MoveModal;
