import React, { Key, useEffect, useMemo, useState } from 'react';
import { RoleSteps } from '../../../components/CommonSteps';
import { Col, InputProps, Row, Space } from 'antd';
import styles from './index.module.scss';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useAsync,
  Form,
  Modal,
  Radio,
  Checkbox,
  Input,
  FormTitle,
} from '@maxtropy/components';
import { commonNameRegex } from '../../../utils/regex';
import { RuleObject, StoreValue } from 'rc-field-form/es/interface';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import usePageStatus, { PageStatus, PageStatusDisplay } from './usePageStatus';
import {
  checkUserNameUniqueness,
  createRole,
  createRoleFromCopy,
  findRoleByCode,
  getIntegratedAppList,
  RoleInfo,
  updateRole,
} from '../../../api/role';
import { RoleStatus, RoleStatusDisplay } from '../../../api/const';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apiV2MiniAppListPost, V2MiniAppListPostResponse } from '@maxtropy/cc-customer-apis-v2';

const Platform = 'platform';

interface EditRoleProps {}

enum EditRoleType {
  COPY = 'copy',
}

type MiniAppItem = Exclude<V2MiniAppListPostResponse['list'], undefined>[number];

const EditRole: React.FC<EditRoleProps> = props => {
  const [form] = Form.useForm();
  let { code } = useParams<{ code: string }>();
  const pageStatus = usePageStatus();
  const [role, setRole] = useState<RoleInfo>();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type: EditRoleType = query.get('type') as EditRoleType;
  const copyId = query.get('copyId');
  const [submitLoading, setSubmitLoading] = useState(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [appletList, setAppletList] = useState<MiniAppItem[]>([]); //小程序列表

  const integratedAppList = useAsync(getIntegratedAppList, { list: [] });

  useEffect(() => {
    if (!code) return;
    if (pageStatus !== PageStatus.CREATE) {
      findRoleByCode(code).then(data => {
        setRole(data);
        form.setFieldsValue({
          name: data.name,
          state: data.state,
          integratedAppIdList: ((data.roleIntegratedAppList ?? []).map(i => i.id) as Key[]).concat(Platform),
          miniAppIds: data.miniAppIds,
        });
      });
    }
  }, [form, code, pageStatus]);

  // 获取小程序绑定列表
  useEffect(() => {
    apiV2MiniAppListPost({}).then(res => setAppletList(res.list ?? []));
  }, []);

  const routes = [{ name: `${PageStatusDisplay[pageStatus!]}角色` }];

  const onFinish = (values: any) => {
    if (pageStatus === PageStatus.CREATE) {
      Modal.confirm({
        content: '确认创建新的角色吗?',
        onOk: () => {
          setSubmitLoading(true);
          let body = {
            ...values,
            integratedAppIdList: (values.integratedAppIdList ?? []).filter((i: Key) => i !== Platform),
          };
          if (type === EditRoleType.COPY) {
            body = {
              ...body,
              copyRoleId: copyId,
            };
            createRoleFromCopy(body)
              .then(data => {
                navigate(`/system/rolePer/role/permissions/update/${data?.code}`);
              })
              .finally(() => {
                setSubmitLoading(false);
              });
          } else {
            createRole(body)
              .then(data => {
                navigate(`/system/rolePer/role/permissions/update/${data?.code}`);
              })
              .finally(() => {
                setSubmitLoading(false);
              });
          }
        },
      });
    } else {
      setSubmitLoading(true);
      let body = {
        ...values,
        integratedAppIdList: (values.integratedAppIdList ?? []).filter((i: Key) => i !== Platform),
      };
      body = { ...body, id: role?.id, code: role?.code };
      updateRole(body)
        .then(() => {
          navigate(`/system/rolePer/role/permissions/update/${role?.code}`);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const nameValidator = (_: RuleObject, name: StoreValue) => {
    if (!commonNameRegex({ start: 0, end: 12 }).test(name)) {
      return Promise.reject(new Error('角色名称仅支持汉字、数字、字母'));
    } else {
      return Promise.resolve();
    }
  };

  const nameUniquenessValidator = (_: RuleObject, name: StoreValue) => {
    return checkUserNameUniqueness(role?.id ? { name, id: String(role.id) } : { name })
      .then(response => {
        if (!response.result) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('该名称已存在'));
        }
      })
      .catch(error => {
        return Promise.reject(new Error(error.message || error.cause.errorMessage));
      });
  };

  const checkOptions = useMemo(() => {
    return appletList.map(item => ({
      label: item.miniAppName,
      value: item.miniAppId!,
    }));
  }, [appletList]);

  const changeRoute = () => {
    navigate(`/system/rolePer/role/permissions/detail/${role?.code}`);
  };

  const onClose = () => {
    if (!disabled) {
      Modal.confirm({
        content: '确认放弃未保存的修改返回列表吗?',
        onOk: () => {
          navigate(`/system/rolePer/role`);
        },
      });
    } else {
      navigate(`/system/rolePer/role`);
    }
  };

  const disabled = pageStatus === PageStatus.DETAIL;

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.roleWrapper}>
      <FormTitle title={`${PageStatusDisplay[pageStatus!]}角色`} />
      <RoleSteps current={0} isDetail={pageStatus === PageStatus.DETAIL} />
      <Row className={styles.formWrapper}>
        <Col span={16} offset={5}>
          <Form
            className={styles.form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateTrigger={['onBlur']}
            form={form}
          >
            {pageStatus !== PageStatus.CREATE && (
              <>
                <Form.Item label="角色模板CODE">
                  <span>{role?.code}</span>
                </Form.Item>
              </>
            )}

            <Form.Item
              label="角色名称"
              name="name"
              validateFirst
              normalize={value => (value ?? '').replaceAll(' ', '')}
              rules={[
                { required: true, message: '请输入角色名称!' },
                { type: 'string', max: 12 },
                { validator: nameValidator },
                { validator: nameUniquenessValidator },
              ]}
            >
              <RoleNameInput disabled={disabled} />
            </Form.Item>

            <Form.Item
              label="角色状态"
              name="state"
              validateFirst
              rules={[{ required: true, message: '请选择角色状态' }]}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={RoleStatus.ENABLE}>{RoleStatusDisplay[RoleStatus.ENABLE]}</Radio>
                <Radio value={RoleStatus.FREEZE}>{RoleStatusDisplay[RoleStatus.FREEZE]}</Radio>
                {pageStatus !== PageStatus.CREATE && (
                  <Radio value={RoleStatus.INVALID}>{RoleStatusDisplay[RoleStatus.INVALID]}</Radio>
                )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="角色应用范围"
              name="integratedAppIdList"
              validateFirst
              initialValue={[Platform]}
              rules={[{ required: true, message: '请选择角色应用范围' }]}
            >
              <Checkbox.Group disabled={disabled} style={{ width: '100%' }} onChange={() => {}}>
                <Row gutter={[0, 10]}>
                  <Col span={6}>
                    <Checkbox disabled value={Platform}>
                      平台
                    </Checkbox>
                  </Col>
                  {(integratedAppList.list ?? []).map(i => (
                    <Col span={6}>
                      <Checkbox key={i.id} value={i.id}>
                        {i.name}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            {/* 小程序角色权限块  如果一个小程序也没有绑定 则标题也不显示 */}
            {appletList.length > 0 ? (
              <Form.Item label="小程序" name="miniAppIds" validateFirst>
                <Checkbox.Group disabled={disabled} options={checkOptions} style={{ width: '100%' }}></Checkbox.Group>
              </Form.Item>
            ) : null}
            <Space size={8} style={{ marginTop: 8 }}>
              <Button
                loading={submitLoading}
                type="primary"
                htmlType={disabled ? 'button' : 'submit'}
                {...(disabled ? { onClick: changeRoute } : {})}
              >
                下一步
              </Button>
              <Button onClick={onClose}>关闭</Button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default EditRole;

const RoleNameInput: React.FC<InputProps> = props => {
  return (
    <>
      <Input style={{ width: 300 }} placeholder="请输入角色名称，最多12字" {...props} />
      <div className={styles.prompt}>
        <ExclamationCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }} />
        <span>角色名称支持汉字、数字、字母，不能与其他预置角色重复，不能与租户内其他自建角色重复！</span>
      </div>
    </>
  );
};
