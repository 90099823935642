import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Outlet, createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import './App.scss';
import Organization from './pages/Organization';
import Layout from './components/Layout';
import SelectStaffLayout from './components/Layout/SelectStaffLayout';
import OperationPassword from './pages/PersonalCenter/OperationPassword';
import Information from './pages/PersonalCenter/Information';
import LoginPasswordForm from './pages/PersonalCenter/LoginPassword';
import Employee from './pages/Employee';
import CreateStaff from './pages/Staff/Create';
import StaffSelection from './pages/StaffSelection';
import Home from './pages/Home';
import RoleList from './pages/Role';
import { EditRole, EditOutLayer } from './pages/RolePermissions/Edit';
import BindOrganization from './pages/RolePermissions/Bind/BindOrganization';
import BindStaff from './pages/RolePermissions/Bind/BindStaff';
import BindDetail from './pages/RolePermissions/Bind/BindDetail';
import EmployeeAuthority from './pages/EmployeeAuthority';
import { PermissionsType } from './common/permissionsConst';

import AuthorizedPermission from './components/AuthorizedPermission';
import QingFlowError from './pages/QingError';
import QlLanding from './pages/QlLanding';
import Qldata from './pages/Qldata';

import CreatePropMgmtDept from './pages/PropMgmtDept/Create';
import DetailPropMgmtDept from './pages/PropMgmtDept/Detail';
import HomeMange from './pages/HomeManage';
import OuMange from './pages/Employee/OuMange';
import HomeLanding from './pages/HomeLanding';
import RootRouteBoundary from './components/RootRouteBoundary';
import PropMgmtDeptList from './pages/PropMgmtDept/PropMgmtDeptList';
import NavigationPreferencePage from './pages/NavigationPreference';
import OrganizationManage from './pages/OrganizationManage';
import { CustomApp, getBase, useThemeContext } from '@maxtropy/components';
import CompositeNavConfig from './pages/CompositeNavConfig';
import CompositiveNavScreen from './pages/CompositeNavScreen';

const RootWrapper = () => {
  const location = useLocation();

  // 在微前端环境下，路由跳转时触发popstate事件，使主应用监听到路由变化
  useEffect(() => {
    if (!window.__MICRO_APP_ENVIRONMENT__) return;
    window.rawWindow.dispatchEvent(new PopStateEvent('popstate', { state: null }));
  }, [location]);

  return <Outlet />;
};

const router = createBrowserRouter(
  [
    // 使用 /* 而非 / 防止未匹配到出现错误
    {
      path: '/*',
      errorElement: <RootRouteBoundary />,
      element: <RootWrapper />,
      children: [
        {
          path: 'select-staff',
          element: (
            <SelectStaffLayout>
              <StaffSelection />
            </SelectStaffLayout>
          ),
        },
        {
          path: 'qingflow/error',
          element: <QingFlowError />,
        },
        {
          element: (
            <Layout>
              <Outlet />
            </Layout>
          ),
          children: [
            {
              index: true,
              element: <HomeLanding />,
            },
            {
              path: 'home',
              element: <Home />,
            },
            {
              // 轻流 节点数据权限
              path: 'ql-data-permissions/manage/approval',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_QL_APPROVE_DATA}>
                  <Qldata />
                </AuthorizedPermission>
              ),
            },
            {
              // 轻流页面中转页--平台应用
              path: 'ql/platform/:permissionCode',
              element: <QlLanding pageType="plantForm" />,
            },
            {
              // 轻流页面中转页--集成应用
              path: 'ql/integrated/:linkId',
              element: <QlLanding pageType="integrated" />,
            },
            {
              path: 'system/customer/info',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PCUSTOMERINFO}>
                  <Organization />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/customer/info/:mcid',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PUPDATEADDCUSTOMER}>
                  <OrganizationManage />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'user/settings/operatePassword',
              element: <OperationPassword />,
            },
            {
              path: 'user/settings/information',
              element: <Information />,
            },
            {
              path: 'user/settings/navigationPreference',
              element: <NavigationPreferencePage />,
            },
            {
              path: 'user/settings/loginPassword',
              element: <LoginPasswordForm />,
            },
            {
              path: 'system/customer/staff',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PSTAFFMANAGE}>
                  <Employee />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/customer/staff/create',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PCREATESTAFF}>
                  <CreateStaff />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/customer/staff/update',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: (
                    <AuthorizedPermission permissionKey={PermissionsType.PUPDATESTAFF}>
                      <CreateStaff />
                    </AuthorizedPermission>
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <AuthorizedPermission permissionKey={PermissionsType.PUPDATESTAFF}>
                      <CreateStaff />
                    </AuthorizedPermission>
                  ),
                },
              ],
            },
            {
              path: 'system/customer/staff/detail/:id',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PSTAFFINFO}>
                  <CreateStaff />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/customer/staff/authority/update/:id',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PUPDATESTAFF}>
                  <EmployeeAuthority />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/customer/staff/authority/detail/:id',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PSTAFFINFO}>
                  <EmployeeAuthority />
                </AuthorizedPermission>
              ),
            },
            {
              // ou
              path: 'system/customer/staff/ou/:id',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PSTAFFMANAGE}>
                  <OuMange />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PROLEMANAGE}>
                  <RoleList />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/create',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PCREATEROLE}>
                  <EditRole />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/update/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PCREATEROLE}>
                  <EditRole />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/detail/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PROLEINFO}>
                  <EditRole />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/permissions/update/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PUPDATEROLE}>
                  <EditOutLayer />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/permissions/detail/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PROLEINFO}>
                  <EditOutLayer />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/permissions/bind/organization/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PROLEBINDSTAFF}>
                  <BindOrganization />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/permissions/bind/staff/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PROLEBINDSTAFF}>
                  <BindStaff />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/rolePer/role/permissions/bind/detail/:code',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.PROLEINFO}>
                  <BindDetail />
                </AuthorizedPermission>
              ),
            },
            // 物业管理
            {
              path: 'system/operation/prop-mgmt-dept',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_PROPERTYMANAGELIST}>
                  <PropMgmtDeptList />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/operation/prop-mgmt-dept/create',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_PROPERTYMANAGEADD}>
                  <CreatePropMgmtDept />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/operation/prop-mgmt-dept/edit/:id',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_PROPERTYMANAGEADD}>
                  <CreatePropMgmtDept isEdit />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/operation/prop-mgmt-dept/detail/:id',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_PROPERTYMANAGELIST}>
                  <DetailPropMgmtDept />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'system/operation/home-manage',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_HOME_PAGE_MANAGE}>
                  <HomeMange />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'nav-workbench/correlation/composite-nav-config',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_SYNTHESIZE_NAVIGATION_CONFIG}>
                  <CompositeNavConfig />
                </AuthorizedPermission>
              ),
            },
            {
              path: 'nav-workbench/correlation/composite-nav-screen',
              element: (
                <AuthorizedPermission permissionKey={PermissionsType.P_SYNTHESIZE_NAVIGATION}>
                  <CompositiveNavScreen />
                </AuthorizedPermission>
              ),
            },
          ],
        },
      ],
    },
  ],
  {
    basename: getBase(),
  }
);

const App: React.FC = props => {
  const { setCurrentTheme, ...theme } = useThemeContext();

  useEffect(() => {
    const dataListener = (data: any) => {
      if (data?.theme) {
        setCurrentTheme?.(data.theme.theme || theme.theme); // 更新子应用的主题
      }
    };

    window.microApp?.addDataListener(dataListener, true);

    return () => {
      window.microApp?.removeDataListener(dataListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <ConfigProvider locale={zhCN} theme={theme}>
        <CustomApp style={{ height: '100%' }}>
          <RouterProvider router={router} />
        </CustomApp>
      </ConfigProvider>
    </div>
  );
};

export default App;
